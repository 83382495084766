/* eslint-disable newline-per-chained-call */
/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Table, Button, Container, FormControl } from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import AirForm from './components/Modals/AIRForm/AIRForm.jsx';
import TablePagination from 'components/GlobalUIs/Pagination';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import ApproveAirForm from './components/Modals/ApproveAirForm/ApproveAirForm.jsx';
import { getPurchaseOrder, selectpurchaseOrder } from 'reducers/purchaseOrderReducer';
import PurchaseOrderModal from './components/Modals/PurchaseOrderModal/PurchaseOrderModal.jsx';

// Assets
import styles from './index.module.scss';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as LucideSVG } from 'assets/svg/lucide_view.svg';
import { checkStringPermission } from 'helpers/filteredPermissions.js';

// Main component
const PurchaseOrder = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const navigate = useNavigate();

  const purchaseOrderState = useSelector(selectpurchaseOrder);
  const { meta, logs } = purchaseOrderState;

  const [data, setData] = useState([]);
  const [showPO, setShowPO] = useState({
    data: null,
    show: false,
  });

  const [showAIR, setShowAIR] = useState({
    data: null,
    show: false,
  });

  const [showApproveAIR, setShowApproveAIR] = useState({
    data: null,
    show: false,
  });

  const [filter, setFilter] = useState({
    to: '',
    page: 1,
    from: '',
    search: '',
    per_page: 5,
    sort_status: '',
  });

  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    const newObj = {
      to: filter?.to,
      q: filter?.search,
      sort_status: value,
      page: filter?.page,
      from: filter?.from,
      ['include[2]']: 'air',
      ['include[0]']: 'item',
      per_page: filter?.per_page,
      ['include[1]']: 'purchase_request',
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        to: filter?.to,
        from: filter?.from,
        ['include[2]']: 'air',
        ['include[0]']: 'item',
        per_page: filter?.per_page,
        sort_status: filter?.sort_status,
        ['include[1]']: 'purchase_request',
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const separateDateAndTime = (dateTimeString) => {
    if (!dateTimeString) return;

    const dateObject = new Date(dateTimeString);

    const month = dateObject.toLocaleString('default', { month: 'short' });
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

    return { date: dateString, time: timeString };
  };

  const tryCatch = async (params) => {
    setData([]);

    try {
      const response = await dispatch(getPurchaseOrder(params));

      const newData = response?.payload?.data?.map((dd) => {
        let filteredItems = [];
        let filteredPR = [];
        let airForm = [];

        if (dd?.relationships?.item?.data) {
          filteredItems = dd?.relationships?.item?.data?.map((item) => {
            const result = response?.payload?.included?.find(
              (ddd) => +ddd?.id === +item?.id && ddd.type === 'purchase_request_items',
            );

            return result;
          });
        }

        if (dd?.relationships?.item?.data) {
          filteredPR = dd?.relationships?.purchase_request?.data?.map((item) => {
            const result = response?.payload?.included?.find(
              (ddd) => +ddd?.id === +item?.id && ddd.type === 'purchase_requests',
            );

            return result;
          });
        }

        if (dd?.relationships?.air?.data && dd?.relationships?.air?.data.length > 0) {
          airForm = dd?.relationships?.air?.data?.map((item) => {
            const result = response?.payload?.included?.find(
              (ddd) => +ddd?.id === +item?.id && ddd.type === 'air',
            );

            return result;
          });
        }

        const { date, time } = separateDateAndTime(dd?.attributes?.created_at);

        return {
          ...dd,
          time: time,
          airData: airForm,
          purchaseDate: date,
          items: filteredItems,
          status: dd?.attributes?.status,
          purchaseRequestData: filteredPR,
          purchaseID: dd?.attributes?.po_no,
          department: filteredPR[0]?.attributes?.department,
          purchaseBy: filteredPR[0]?.attributes?.requested_by,
        };
      });

      setData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleInventory = () => {
    const unfinalParams = {
      to: filter?.to,
      q: filter?.search,
      page: filter?.page,
      from: filter?.from,
      ['include[2]']: 'air',
      ['include[0]']: 'item',
      per_page: filter?.per_page,
      sort_status: filter?.sort_status,
      ['include[1]']: 'purchase_request',
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: filter?.q,
      to: filter?.to,
      from: filter?.from,
      ['include[2]']: 'air',
      ['include[0]']: 'item',
      sort_status: filter?.sort_status,
      ['include[1]']: 'purchase_request',
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleDates = (e) => {
    const { name, value } = e.target;

    const _dateRange = {
      to: filter?.to,
      from: filter?.from,
    };

    _dateRange[name] = value;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      if (_dateRange?.from !== '' && _dateRange?.to !== '') {
        setFilter((prev) => ({ ...prev, page: 1 }));

        const newObj = {
          page: 1,
          q: filter?.search,
          to: _dateRange?.to,
          ['include[2]']: 'air',
          ['include[0]']: 'item',
          from: _dateRange?.from,
          sort_status: filter?.sort_status,
          per_page: filter?.per_page,
          ['include[1]']: 'purchase_request',
        };

        const params = objectCleaner(newObj);
        tryCatch(params);
      }
    }, 1000);
  };

  useEffect(() => {
    handleInventory();
  }, []);

  console.log('DATA', data);

  const canCreateAIR = checkStringPermission('can receive and inspect items received');
  const canReceivedAndSigned = checkStringPermission('can record received items and sign air');

  return (
    <PrivateLayout pageTitle='Purchase Order'>
      <div className={styles?.inventory}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <h5>Purchase Order List</h5>
                  </div>

                  <div className={styles?.filter}>
                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='search'
                        placeholder='Search'
                        value={filter?.search}
                        onChange={handleSearch}
                      />
                    </Form.Group>

                    <div className={styles?.from}>
                      <p className={styles?.title}>From</p>

                      <FormControl
                        name='from'
                        type='date'
                        value={filter?.from}
                        onChange={handleDates}
                        className={styles?.fromInput}
                        max={new Date().toISOString().slice(0, 10)}
                      />
                    </div>

                    <div className={styles?.to}>
                      <p className={styles?.title}>To</p>

                      <FormControl
                        name='to'
                        type='date'
                        value={filter?.to}
                        min={filter?.from}
                        className={styles?.toInput}
                        max={new Date().toISOString().slice(0, 10)}
                        onChange={(e) => {
                          if (filter?.from !== '') {
                            handleDates(e);
                            return;
                          }

                          toast.warning('Please select from first');
                        }}
                      />
                    </div>

                    <div className={styles?.selectContainer}>
                      <Form.Select
                        name='sort_status'
                        onChange={handleSort}
                        className={styles?.select}
                        value={filter?.sort_status}
                      >
                        <option value='' hidden>
                          Sort By
                        </option>
                        <option value='for approval'>For Approval</option>
                        <option value='approved'>Approved</option>
                        <option value='rejected'>Rejected</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>

                <div className={styles?.body}>
                  <Table responsive className={styles?.processTable}>
                    <thead>
                      <tr>
                        <th>Purchase ID</th>
                        <th>Purchase by</th>
                        <th>Purchased Date</th>
                        <th>TIme</th>
                        <th>Department</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length >= 1 &&
                        data.map((dd) => (
                          <tr key={dd?.id}>
                            <td>{dd?.purchaseID}</td>
                            <td>{dd?.purchaseBy}</td>
                            <td>{dd?.purchaseDate}</td>
                            <td>{dd?.time}</td>
                            <td>{dd?.department}</td>
                            <td>
                              <span
                                className={
                                  dd?.status === 'for approval'
                                    ? styles?.forApproval
                                    : styles[dd?.status]
                                }
                              >
                                {dd?.status}
                              </span>
                            </td>
                            <td className={styles?.actions}>
                              <Button
                                onClick={() => {
                                  setShowPO({
                                    data: dd,
                                    show: true,
                                  });
                                }}
                                className={styles?.viewPO}
                              >
                                <LucideSVG />
                                View PO
                              </Button>

                              {dd?.airData &&
                                dd?.airData.length === 0 &&
                                dd?.status === 'approved' &&
                                canCreateAIR && (
                                  <Button
                                    onClick={() => {
                                      setShowAIR({
                                        data: dd,
                                        show: true,
                                      });
                                    }}
                                    className={styles?.viewPO}
                                  >
                                    <LucideSVG />
                                    Create AIR
                                  </Button>
                                )}

                              {/* Executive Officer */}
                              {dd?.airData &&
                                dd?.airData.length > 0 &&
                                canReceivedAndSigned === false &&
                                canCreateAIR && (
                                  <Button
                                    onClick={() => {
                                      setShowAIR({
                                        data: dd,
                                        show: true,
                                      });
                                    }}
                                    className={styles?.viewPO}
                                  >
                                    <LucideSVG />
                                    Check AIR
                                  </Button>
                                )}

                              {/* Supply Officer */}
                              {dd?.airData && dd?.airData.length > 0 && canReceivedAndSigned && (
                                <Button
                                  onClick={() => {
                                    setShowApproveAIR({
                                      data: dd,
                                      show: true,
                                    });
                                  }}
                                  className={styles?.viewPO}
                                >
                                  <LucideSVG />
                                  Check AIR
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10}>
                          <TablePagination
                            meta={meta}
                            setParams={setFilter}
                            loading={logs?.isLoading}
                            handlePaginationClick={handlePaginationClick}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </div>
              <PurchaseOrderModal
                show={showPO?.show}
                setShowPO={setShowPO}
                tableData={showPO?.data}
                handlePurchaseOrder={handleInventory}
              />

              {/* Executive Officer */}
              <AirForm
                show={showAIR?.show}
                setShowAIR={setShowAIR}
                tableData={showAIR?.data}
                handlePurchaseOrder={handleInventory}
              />

              {/* Supply Officer */}
              <ApproveAirForm
                show={showApproveAIR?.show}
                setShowAIR={setShowApproveAIR}
                tableData={showApproveAIR?.data}
                handlePurchaseOrder={handleInventory}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default PurchaseOrder;
