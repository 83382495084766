/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  createPO,
  denyOrderForm,
  approveOrderForm,
  getPurchaseOrder,
  updatePurchaseOrder,
} from './thunks/purchaseOrderThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  users: [],
  meta: null,
  logs: initialLog,
  createPOLogs: initialLog,
  updateLogs: initialLog,
  approveLogs: initialLog,
  declinedLogs: initialLog,
};

const slice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {},
  extraReducers: {
    [createPO.fulfilled]: (state, action) => {
      state.createPOLogs.isSuccess = true;
      state.createPOLogs.isLoading = false;
    },
    [createPO.pending]: (state) => {
      state.createPOLogs.error = '';
      state.createPOLogs.isFailed = false;
      state.createPOLogs.isLoading = true;
      state.createPOLogs.isSuccess = false;
    },
    [createPO.rejected]: (state, action) => {
      state.createPOLogs.isFailed = true;
      state.createPOLogs.isLoading = false;
      if (action?.payload?.errors) {
        state.createPOLogs.error = action?.payload?.errors[0]?.detail;
      } else {
        state.createPOLogs.error = action?.payload?.message?.detail;
      }
    },
    [getPurchaseOrder.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.users = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getPurchaseOrder.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getPurchaseOrder.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [updatePurchaseOrder.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [updatePurchaseOrder.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [updatePurchaseOrder.rejected]: (state, action) => {
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
      state.updateLogs.error = action?.payload?.errors[0]?.detail;
    },
    [approveOrderForm.fulfilled]: (state, action) => {
      state.approveLogs.isSuccess = true;
      state.approveLogs.isLoading = false;
    },
    [approveOrderForm.pending]: (state) => {
      state.approveLogs.error = '';
      state.approveLogs.isFailed = false;
      state.approveLogs.isLoading = true;
      state.approveLogs.isSuccess = false;
    },
    [approveOrderForm.rejected]: (state, action) => {
      state.approveLogs.isFailed = true;
      state.approveLogs.isLoading = false;
      state.approveLogs.error = action?.payload?.errors[0]?.detail;
    },
    [denyOrderForm.fulfilled]: (state, action) => {
      state.declinedLogs.isSuccess = true;
      state.declinedLogs.isLoading = false;
    },
    [denyOrderForm.pending]: (state) => {
      state.declinedLogs.error = '';
      state.declinedLogs.isFailed = false;
      state.declinedLogs.isLoading = true;
      state.declinedLogs.isSuccess = false;
    },
    [denyOrderForm.rejected]: (state, action) => {
      state.declinedLogs.isFailed = true;
      state.declinedLogs.isLoading = false;
      state.declinedLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: purchaseOrderActions, reducer: purchaseOrderReducer } = slice;

export const { updateUserinfo } = purchaseOrderActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.purchaseOrder || initialState;

export const selectpurchaseOrder = createSelector(
  [selectDomain],
  (purchaseOrderState) => purchaseOrderState,
);

/* --------------------------------------------------------------------------------- */

export { createPO, getPurchaseOrder, updatePurchaseOrder, approveOrderForm, denyOrderForm };
