/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Form, Image, Container, Row, Col } from 'react-bootstrap';

// Components
import { selectAuth } from 'reducers/authReducer';
import BarChart from 'components/GlobalUIs/BarChartUI';
import HistorySidebar from './components/HistorySidebar';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';

// Assets
import styles from './index.module.scss';
import { ReactComponent as ItemSVG } from 'assets/svg/item.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/arrow.svg';
import { ReactComponent as ArrowDownSVG } from 'assets/svg/arrow down.svg';

// Cards Data
const cardData = {
  itemOut: 16,
  totalIn: 60,
  totalItem: 200,
};

// Main component
const Dashboard = () => {
  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const [cardData, setCardData] = useState({
    borrowed: '',
    returned: '',
    items: '',
  });

  const [overview, setOverview] = useState('Month');
  const [options, setOptions] = useState({
    plugins: {
      responsive: true,
      title: {
        display: false,
        text: 'Overview',
      },
    },
  });

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const getOverview = async (filter = '') => {
    const selectedFilter = filter !== '' ? filter : overview;

    let params = {};

    const date = new Date();
    const year = date.getFullYear();

    if (selectedFilter === 'Month') {
      params = {
        year: year,
      };
    }

    try {
      axios
        .get('api/overview', {
          params: params,
        })
        .then((response) => {
          if (selectedFilter === 'Month') {
            const resData = response?.data?.data[0];
            setData({
              labels: resData?.map((dd) => dd?.month),
              datasets: [
                {
                  label: '',
                  barThickness: 16,
                  borderRadius: 20,
                  backgroundColor: '#4462FF',
                  data: resData?.map((dd) => dd?.total),
                },
              ],
            });
          } else {
            const resData = response?.data?.data[0];
            setData({
              labels: resData?.map((dd) => dd?.year),
              datasets: [
                {
                  label: '',
                  barThickness: 16,
                  borderRadius: 20,
                  backgroundColor: '#4462FF',
                  data: resData?.map((dd) => dd?.total),
                },
              ],
            });
          }
        });
    } catch (error) {
      console.log('Error', error);
    }
  };

  const getCardData = async (filter = '') => {
    try {
      axios.get('api/cards').then((response) => {
        console.log('response?.data', response?.data);
        const resData = response?.data?.data[0];

        setCardData({
          items: resData?.ITEMS,
          borrowed: resData?.BORROWED,
          returned: resData?.RETURNED,
        });
      });
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleFilter = (e) => {
    setOverview(e.target.value);
    getOverview(e.target.value);
  };

  useEffect(() => {
    getOverview();
    getCardData();
  }, []);

  console.log('data', data);

  // useEffect(() => {
  //   switch (overview) {
  //     case 'Month':
  //       setData({
  //         labels: monthlyData?.map((data) => data?.month),
  //         datasets: [
  //           {
  //             label: '',
  //             backgroundColor: '#4462FF',
  //             data: monthlyData?.map((data) => data?.total),
  //             barThickness: 16,
  //             borderRadius: 20,
  //           },
  //         ],
  //       });
  //       break;
  //     case 'Year':
  //       setData({
  //         labels: yearlyData?.map((data) => data?.month),
  //         datasets: [
  //           {
  //             label: '',
  //             backgroundColor: '#4462FF',
  //             data: yearlyData?.map((data) => data?.value),
  //             barThickness: 16,
  //             borderRadius: 20,
  //           },
  //         ],
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  // }, [overview]);

  return (
    <PrivateLayout pageTitle='Dashboard'>
      <div className={styles?.dashboard}>
        <Container>
          <div className={styles?.CardsTitle}>
            <div className={styles?.title}>
              <h5>Hello {userInfo?.attributes?.role}, Good Morning!</h5>
              <p>Dashboard gives you views of key performance or business process</p>
            </div>
            <div className={styles?.cards}>
              <div className={styles?.card}>
                <div className={styles?.image}>
                  <ItemSVG />
                </div>

                <div className={styles?.text}>
                  <p>Total Item</p>

                  <span>{cardData?.items}</span>
                </div>
              </div>

              <div className={styles?.card}>
                <div className={styles?.image}>
                  <ArrowSVG />
                </div>

                <div className={styles?.text}>
                  <p>Borrowed</p>

                  <span>{cardData?.borrowed}</span>
                </div>
              </div>

              <div className={styles?.card}>
                <div className={styles?.image}>
                  <ArrowDownSVG />
                </div>

                <div className={styles?.text}>
                  <p>Returned</p>

                  <span>{cardData?.returned}</span>
                </div>
              </div>
            </div>
          </div>

          <Row style={{ rowGap: 10 }}>
            <Col sm={8}>
              <div className={styles?.overview}>
                <div className={styles?.header}>
                  <h5>Overview</h5>

                  <div className={styles?.selectContainer}>
                    <Form.Select
                      value={overview}
                      className={styles?.select}
                      onChange={handleFilter}
                    >
                      <option value='Month'>Month</option>
                      <option value='Year'>Year</option>
                    </Form.Select>
                  </div>
                </div>

                <div className={styles?.body}>
                  <BarChart options={options} data={data} />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <HistorySidebar />
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default Dashboard;
