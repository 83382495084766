/* eslint-disable newline-per-chained-call */
/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Table, Button, Container, FormControl } from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import { getSAI, selectSai } from 'reducers/saiReducer.jsx';
import TablePagination from 'components/GlobalUIs/Pagination';
import SiaForm from './components/Modals/SiaForm/SiaForm.jsx';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import ConditionalRender from 'components/ConditionalRender/index.jsx';
import ViewSiaForm from './components/Modals/ViewSiaForm/ViewSiaForm.jsx';

// Assets
import styles from './index.module.scss';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { checkStringPermission } from 'helpers/filteredPermissions.js';
import { ReactComponent as LucideSVG } from 'assets/svg/lucide_view.svg';
import { ReactComponent as CirclePlus } from 'assets/svg/circle plus.svg';

// Main component
const Sai = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const navigate = useNavigate();

  const saiState = useSelector(selectSai);
  const { meta, logs } = saiState;

  const [data, setData] = useState([]);
  const [showSIA, setShowSIA] = useState({
    data: null,
    show: false,
  });
  const [showViewSIA, setShowViewSIA] = useState({
    data: null,
    show: false,
  });

  const [filter, setFilter] = useState({
    to: '',
    page: 1,
    from: '',
    search: '',
    per_page: 5,
    sort_status: '',
  });

  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    const newObj = {
      to: filter?.to,
      q: filter?.search,
      sort_status: value,
      page: filter?.page,
      from: filter?.from,
      ['include[]']: 'item',
      per_page: filter?.per_page,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        to: filter?.to,
        from: filter?.from,
        ['include[]']: 'item',
        per_page: filter?.per_page,
        sort_status: filter?.sort_status,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const separateDateAndTime = (dateTimeString) => {
    if (!dateTimeString) return;

    const dateObject = new Date(dateTimeString);

    const month = dateObject.toLocaleString('default', { month: 'short' });
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

    return { date: dateString, time: timeString };
  };

  const tryCatch = async (params) => {
    setData([]);

    try {
      const response = await dispatch(getSAI(params));

      const newData = response?.payload?.data?.map((dd) => {
        let filteredItems = [];

        if (dd?.relationships?.item?.data) {
          filteredItems = dd?.relationships?.item?.data?.map((item) => {
            const result = response?.payload?.included?.find(
              (ddd) => +ddd?.id === +item?.id && ddd.type === 'sai_items',
            );

            return result;
          });
        }

        const { date, time } = separateDateAndTime(dd?.attributes?.created_at);

        return {
          ...dd,
          time: time,
          purchaseDate: date,
          items: filteredItems,
          status: dd?.attributes?.status,
        };
      });

      setData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleInventory = () => {
    const unfinalParams = {
      to: filter?.to,
      q: filter?.search,
      page: filter?.page,
      from: filter?.from,
      ['include[]']: 'item',
      per_page: filter?.per_page,
      sort_status: filter?.sort_status,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: filter?.q,
      to: filter?.to,
      from: filter?.from,
      ['include[]']: 'item',
      sort_status: filter?.sort_status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleDates = (e) => {
    const { name, value } = e.target;

    const _dateRange = {
      to: filter?.to,
      from: filter?.from,
    };

    _dateRange[name] = value;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      if (_dateRange?.from !== '' && _dateRange?.to !== '') {
        setFilter((prev) => ({ ...prev, page: 1 }));

        const newObj = {
          page: 1,
          q: filter?.search,
          to: _dateRange?.to,
          ['include[]']: 'item',
          from: _dateRange?.from,
          per_page: filter?.per_page,
          sort_status: filter?.sort_status,
        };

        const params = objectCleaner(newObj);
        tryCatch(params);
      }
    }, 1000);
  };

  useEffect(() => {
    handleInventory();
  }, []);

  const canCreateSAI = checkStringPermission('can prepare SAI');

  return (
    <PrivateLayout pageTitle='SAI'>
      <div className={styles?.sai}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <h5>Supply Availability Inquiry</h5>
                  </div>

                  <div className={styles?.filter}>
                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='search'
                        placeholder='Search'
                        value={filter?.search}
                        onChange={handleSearch}
                      />
                    </Form.Group>

                    <div className={styles?.from}>
                      <p className={styles?.title}>From</p>

                      <FormControl
                        name='from'
                        type='date'
                        value={filter?.from}
                        onChange={handleDates}
                        className={styles?.fromInput}
                        max={new Date().toISOString().slice(0, 10)}
                      />
                    </div>

                    <div className={styles?.to}>
                      <p className={styles?.title}>To</p>

                      <FormControl
                        name='to'
                        type='date'
                        value={filter?.to}
                        min={filter?.from}
                        className={styles?.toInput}
                        max={new Date().toISOString().slice(0, 10)}
                        onChange={(e) => {
                          if (filter?.from !== '') {
                            handleDates(e);
                            return;
                          }

                          toast.warning('Please select from first');
                        }}
                      />
                    </div>

                    <div className={styles?.selectContainer}>
                      <Form.Select
                        name='sort_status'
                        onChange={handleSort}
                        className={styles?.select}
                        value={filter?.sort_status}
                      >
                        <option value='' hidden>
                          Sort By
                        </option>
                        <option value='for approval'>For Approval</option>
                        <option value='for releasing'>For Releasing</option>
                        <option value='released'>Released</option>
                      </Form.Select>
                    </div>

                    <ConditionalRender
                      condition={canCreateSAI}
                      renderIf={
                        <Button
                          onClick={() => {
                            setShowSIA({
                              show: true,
                              data: null,
                            });
                          }}
                          variant='success'
                          className={styles?.requestSettings}
                        >
                          <CirclePlus />
                          Create SIA
                        </Button>
                      }
                    />
                  </div>
                </div>

                <div className={styles?.body}>
                  <Table responsive className={styles?.processTable}>
                    <thead>
                      <tr>
                        <th>Division</th>
                        <th>Office</th>
                        <th>Code</th>
                        <th>Date of Inquiry</th>
                        <th>Number Item Inquired</th>
                        <th>Inquired by</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length >= 1 &&
                        data.map((dd) => {
                          const { date } = separateDateAndTime(dd?.attributes?.created_at);

                          return (
                            <tr key={dd?.id}>
                              <td>{dd?.attributes?.division}</td>
                              <td>{dd?.attributes?.office}</td>
                              <td>{dd?.attributes?.code}</td>
                              <td>{date}</td>
                              <td>{dd?.items.length}</td>
                              <td>{dd?.attributes?.inquired_by}</td>
                              <td>
                                <span
                                  className={
                                    dd?.status === 'for approval'
                                      ? styles?.forApproval
                                      : styles[dd?.status]
                                  }
                                >
                                  {dd?.status}
                                </span>
                              </td>
                              <td className={styles?.actions}>
                                <Button
                                  onClick={() => {
                                    setShowViewSIA({
                                      data: dd,
                                      show: true,
                                    });
                                  }}
                                  className={styles?.viewPO}
                                >
                                  <LucideSVG />
                                  View SIA
                                </Button>

                                {/* {dd?.airData &&
                                dd?.airData.length === 0 &&
                                dd?.status === 'approved' &&
                                canCreateAIR && (
                                  <Button
                                    onClick={() => {
                                      setShowAIR({
                                        data: dd,
                                        show: true,
                                      });
                                    }}
                                    className={styles?.viewPO}
                                  >
                                    <LucideSVG />
                                    Create AIR
                                  </Button>
                                )}

                              {dd?.airData && dd?.airData.length > 0 && (
                                <Button
                                  onClick={() => {
                                    setShowAIR({
                                      data: dd,
                                      show: true,
                                    });
                                  }}
                                  className={styles?.viewPO}
                                >
                                  <LucideSVG />
                                  Check AIR
                                </Button>
                              )} */}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10}>
                          <TablePagination
                            meta={meta}
                            setParams={setFilter}
                            loading={logs?.isLoading}
                            handlePaginationClick={handlePaginationClick}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </div>

              <SiaForm
                show={showSIA?.show}
                setShowSIA={setShowSIA}
                tableData={showSIA?.data}
                handleRefreshData={handleInventory}
              />

              <ViewSiaForm
                show={showViewSIA?.show}
                setShowSIA={setShowViewSIA}
                tableData={showViewSIA?.data}
                handleRefreshData={handleInventory}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default Sai;
