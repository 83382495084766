import { configureStore } from '@reduxjs/toolkit';

import { saiReducer } from 'reducers/saiReducer';
import { authReducer } from 'reducers/authReducer';
import { usersReducer } from 'reducers/usersReducer';
import { rolesReducer } from 'reducers/rolesReducer';
import { sampleReducer } from 'reducers/sampleReducer';
import { airFormReducer } from 'reducers/airFormReducer';
import { requestReducer } from 'reducers/requestReducer';
import { approverReducer } from 'reducers/approverReducer';
import { inventoryReducer } from 'reducers/inventoryReducer';
import { auditLogsReducer } from 'reducers/auditLogsReducer';
import { permissionsReducer } from 'reducers/permissionsReducer';
import { purchaseOrderReducer } from 'reducers/purchaseOrderReducer';
import { purchaseRequestReducer } from 'reducers/purchaseRequestReducer';

export const store = configureStore({
  reducer: {
    sai: saiReducer,
    auth: authReducer,
    users: usersReducer,
    roles: rolesReducer,
    sample: sampleReducer,
    request: requestReducer,
    airForm: airFormReducer,
    approver: approverReducer,
    inventory: inventoryReducer,
    auditLogs: auditLogsReducer,
    permissions: permissionsReducer,
    purchaseOrder: purchaseOrderReducer,
    purchaseRequest: purchaseRequestReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
