/* eslint-disable multiline-comment-style */
/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FormControl, Table, Image } from 'react-bootstrap';

import Remarks from '../Remarks';
import { checkEmpty } from 'utils/validator';
import SelectUser from 'components/Layouts/SelectUser';
import { createAirForm, selectAIR, updateAirForm } from 'reducers/airFormReducer';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';
import { denyOrderForm, approveOrderForm } from 'reducers/purchaseOrderReducer';

import styles from './AIRForm.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

const AirForm = ({ show = false, setShowAIR, tableData, handlePurchaseOrder }) => {
  const dispatch = useDispatch();

  const stateAIRFormState = useSelector(selectAIR);
  const { createAirFormLogs, updateAirFormLogs } = stateAIRFormState;

  const [data, setData] = useState({
    id: '',
    lgu: '',
    pONo: '',
    airNo: '',
    status: '',
    supplier: '',
    pONoDate: '',
    invoiceNo: '',
    acceptedBy: '',
    inspectedBy: '',
    acceptedDate: '',
    acceptedByID: '',
    invoiceNoDate: '',
    inspectedDate: '',
    isVerified: false,
    inspectedByID: '',
    acceptedSelection: '',
    acceptedBySignature: null,
    inspectedBySignature: null,
    tableData: {
      airData: [],
    },
    items: [
      {
        id: uuidv4(),
        itemNo: '',
        quantity: '',
        unitOfIssue: '',
        itemDescription: '',
        estimatedUnitCost: '',
        estimatedCost: '',
      },
    ],
  });

  const [showRemarks, setShowRemarks] = useState(false);
  const [supplierOptions, setSupplierOptions] = useState([]);

  const handleClose = () => {
    setShowAIR(false);

    setData({
      lgu: '',
      prNo: '',
      saiNo: '',
      address: '',
      alobsNo: '',
      prNoDate: '',
      supplier: '',
      saiNoDate: '',
      alobsNoDate: '',
      items: [
        {
          id: uuidv4(),
          itemNo: '',
          quantity: '',
          unitOfIssue: '',
          itemDescription: '',
          estimatedUnitCost: '',
          estimatedCost: '',
        },
      ],
      purpose: '',
      requestedBy: '',
      requestedDesignation: '',
      approvedBy: '',
      approvedDesignation: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const changeCheckbox = (e) => {
    const { name, checked } = e.target;

    setData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSupplyOfficer = (e) => {
    setData((prev) => {
      return {
        ...prev,
        propertyOfficer: e,
        propertyOfficerID: e?.id,
        propertyOfficerSignature: e?.attributes?.signature,
      };
    });
  };

  const handleSave = async () => {
    const saveData = {
      invoice_no: data?.invoiceNo,
      purchase_order_id: data?.pONo,
      invoice_date: data?.invoiceNoDate,
      inspection_date: data?.inspectedDate,
      inspection_selection: data?.isVerified,
      requisitioning_office: data?.department,
      inspection_officer_id: data?.inspectedByID,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData, ['items']);

    if (emptyCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    // if (saveData?.isVerified === false) {
    //   toast.error('Please check the inspected and verified button');
    //   return;
    // }

    const formData = new FormData();
    formData.append('invoice_no', saveData?.invoice_no);
    formData.append('invoice_date', saveData?.invoice_date);
    formData.append('inspection_date', saveData?.inspection_date);
    formData.append('purchase_order_id', saveData?.purchase_order_id);
    formData.append('inspection_officer_id', saveData?.inspection_officer_id);
    formData.append('requisitioning_office', saveData?.requisitioning_office);
    formData.append('inspection_selection', saveData?.inspection_selection ? 1 : 0);

    try {
      const response = await dispatch(createAirForm(formData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseOrder();
        toast.info('Successfully Created AIR Form');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdate = async () => {
    const saveData = {
      invoice_no: data?.invoiceNo,
      purchase_order_id: data?.pONo,
      invoice_date: data?.invoiceNoDate,
      inspection_date: data?.inspectedDate,
      inspection_selection: data?.isVerified,
      requisitioning_office: data?.department,
      inspection_officer_id: data?.inspectedByID,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData);

    if (emptyCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    const formData = new URLSearchParams();
    formData.append('invoice_no', saveData?.invoice_no);
    formData.append('invoice_date', saveData?.invoice_date);
    formData.append('inspection_date', saveData?.inspection_date);
    formData.append('purchase_order_id', saveData?.purchase_order_id);
    formData.append('inspection_officer_id', saveData?.inspection_officer_id);
    formData.append('requisitioning_office', saveData?.requisitioning_office);
    formData.append('inspection_selection', saveData?.inspection_selection ? 1 : 0);

    try {
      const response = await dispatch(updateAirForm({ params: formData, id: data?.airNo }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseOrder();
        toast.info('Successfully Updated AIR Form');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleRequisitionOfficer = (e) => {
    setData((prev) => {
      return {
        ...prev,
        inspectedBy: e,
        inspectedByID: e?.id,
        inspectedBySignature: e?.attributes?.signature,
      };
    });
  };

  const handleSelectedSupplier = (e) => (name) => {
    setData((prev) => {
      return {
        ...prev,
        [name]: e,
        address: e?.attributes?.supplier_address,
      };
    });
  };

  const separateDateAndTime = (dateTimeString) => {
    if (!dateTimeString) return;

    const dateObject = new Date(dateTimeString);

    const month = dateObject.toLocaleString('default', { month: 'short' });
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

    return { date: dateString, time: timeString };
  };

  const handleApprove = async () => {
    try {
      const response = await dispatch(approveOrderForm(data?.id));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseOrder();
        toast.info('Successfully Approved Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSubmitRemarks = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('reject_remarks', data?.remarks);

      const response = await dispatch(denyOrderForm({ params: formData, id: data?.id }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        closeRemarks();
        handlePurchaseOrder();
        toast.info('Successfully Declined Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const openRemarks = () => {
    setShowRemarks(true);
  };

  const closeRemarks = () => {
    setShowRemarks(false);
  };

  useEffect(() => {
    if (show && tableData) {
      console.log('TABLE DATA', tableData);

      const getSuppliers = async () => {
        try {
          axios.get('api/supplier').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.supplier_name,
                label: dd?.attributes?.supplier_name,
              };
            });

            setSupplierOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      getSuppliers();

      try {
        axios
          .get('api/users', {
            params: {
              per_page: 1000,
              ['roles[0]']: 'Account Officer',
              ['roles[1]']: 'Supply Officer',
              ['roles[2]']: 'Executive Officer',
            },
          })
          .then((response) => {
            const items = tableData?.items?.map((dd) => ({
              ...dd,
              itemNo: dd?.attributes?.item_no,
              quantity: dd?.attributes?.quantity,
              unitOfIssue: dd?.attributes?.unit_of_issue,
              estimatedCost: dd?.attributes?.estimated_cost,
              itemDescription: dd?.attributes?.item_description,
              estimatedUnitCost: dd?.attributes?.estimated_unit_cost,
            }));

            const haveData = tableData?.airData?.length > 0;

            const isOne = (input) => input === 1;

            setData((prev) => ({
              ...prev,
              items: items,
              tableData: tableData,
              lgu: tableData?.attributes?.lgu,
              department: tableData?.department,
              pONo: tableData?.attributes?.po_no,
              status: tableData?.attributes?.status,
              supplier: tableData?.attributes?.supplier,
              pONoDate: tableData?.attributes?.po_no_date,
              airStatus: haveData ? tableData?.airData[0]?.attributes?.status : '',
              invoiceNo: haveData ? tableData?.airData[0]?.attributes?.invoice_no : '',
              invoiceNoDate: haveData ? tableData?.airData[0]?.attributes?.invoice_date : '',
              receivedDate: haveData ? tableData?.airData[0]?.attributes?.acceptance_date : '',
              inspectedDate: haveData ? tableData?.airData[0]?.attributes?.inspection_date : '',
              acceptance: haveData ? tableData?.airData[0]?.attributes?.acceptance_selection : '',
              propertyOfficer: haveData ? tableData?.airData[0]?.attributes?.property_officer : '',
              inspectedByID: haveData
                ? tableData?.airData[0]?.attributes?.inspection_officer_id
                : '',
              isVerified: haveData
                ? isOne(tableData?.airData[0]?.attributes?.inspection_selection)
                : false,
              inspectedBySignature: haveData
                ? tableData?.airData[0]?.attributes?.inspection_officer_esignature
                : null,
              supplyOfficerName: haveData
                ? {
                    label: tableData?.airData[0]?.attributes?.inspection_officer,
                    value: tableData?.airData[0]?.attributes?.inspection_officer,
                  }
                : '',
              airNo: haveData ? tableData?.airData[0]?.attributes?.air_no : '',
              propertyOfficerSignature: haveData
                ? tableData?.airData[0]?.attributes?.property_officer_esignature
                : '',
            }));
          });
      } catch (error) {
        console.log('Error', error);
      }
    }
  }, [show, tableData]);

  const isEdit =
    data?.tableData?.airData?.length > 0 &&
    data?.tableData?.airData[0]?.attributes?.status === 'for releasing';

  const isCreate = data?.tableData?.airData?.length === 0;

  const canReceivedAndSigned = checkStringPermission('can record received items and sign air');

  return (
    <div className={`${styles?.addItem} ${show ? styles?.active : ''}`}>
      <div className={styles?.padding}>
        <div className={styles?.header}>
          <p>ACCEPTANCE & INSPECTION REPORT</p>

          <CloseSVG className={styles?.close} onClick={handleClose} />
        </div>

        <div className={styles?.body}>
          <hr className={styles?.hr} />

          <Form.Group className={styles?.centerGroup}>
            <FormControl disabled name='lgu' value={data?.lgu} placeholder='Enter Here' />
            <Form.Label>LGU</Form.Label>
          </Form.Group>

          <div className={styles?.formHeader}>
            <div className={styles?.horizontalLine}></div>
            <Form.Group className={styles?.group}>
              <Form.Label>Supplier:</Form.Label>
              <FormControl
                disabled
                name='supplier'
                value={data?.supplier}
                onChange={handleChange}
                placeholder='Enter Here'
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>AIR No.</Form.Label>
              <FormControl
                disabled
                name='airNo'
                value={data?.airNo}
                onChange={handleChange}
                placeholder='Enter Here'
              />
            </Form.Group>
            <Form.Group className={styles?.group}>
              <Form.Label>P.O. No.:</Form.Label>
              <FormControl
                disabled
                name='pONo'
                value={data?.pONo}
                onChange={handleChange}
                placeholder='Enter Here'
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>Date:</Form.Label>
              <FormControl
                disabled
                name='pONoDate'
                value={data?.pONoDate}
                onChange={handleChange}
                placeholder='Enter Here'
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>Invoice No.:</Form.Label>
              <FormControl
                name='invoiceNo'
                onChange={handleChange}
                value={data?.invoiceNo}
                placeholder='Enter Here'
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>Date:</Form.Label>
              <FormControl
                type='date'
                name='invoiceNoDate'
                onChange={handleChange}
                placeholder='Enter Here'
                value={data?.invoiceNoDate}
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>Requisitioning Office / Department :</Form.Label>
              <FormControl
                disabled
                name='department'
                onChange={handleChange}
                value={data?.department}
                placeholder='Enter Here'
              />
            </Form.Group>
          </div>

          <div className={styles?.form}>
            <Table>
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Unit</th>
                  <th>Item Description</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data?.items &&
                  data?.items?.map((dd) => {
                    return (
                      <tr key={dd?.id}>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemNo'
                            value={dd?.itemNo}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='unitOfIssue'
                            value={dd?.unitOfIssue}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemDescription'
                            placeholder='Enter Here'
                            value={dd?.itemDescription}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='quantity'
                            value={dd?.quantity}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='estimatedUnitCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedUnitCost}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          <div className={styles?.header}>
            <div>ACCEPTANCE</div>
            <div>INSPECTION</div>
          </div>
          <div className={styles?.acceptanceInspection}>
            <div className={styles?.verticalLine}></div>

            <div className={styles?.acceptance}>
              <div
                className={styles?.dateReceived}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <Form.Label>Date Received:</Form.Label>
                <FormControl
                  type='date'
                  name='receivedDate'
                  onChange={handleChange}
                  value={data?.receivedDate}
                  readOnly={canReceivedAndSigned === false}
                />
              </div>

              <Form.Check
                value='Complete'
                label='Complete'
                name='acceptance'
                onChange={handleChange}
                checked={data?.acceptance === 'Complete'}
                disabled={canReceivedAndSigned === false}
              />

              <Form.Check
                value='Partial'
                label='Partial'
                name='acceptance'
                onChange={handleChange}
                checked={data?.acceptance === 'Partial'}
                disabled={canReceivedAndSigned === false}
              />

              <Form.Group>
                <div className={styles?.input}>
                  {data?.propertyOfficerSignature && data?.propertyOfficerSignature !== null && (
                    <Image
                      className={styles?.signatureImage}
                      src={data?.propertyOfficerSignature}
                    />
                  )}

                  <div className={styles?.requestedBy}>
                    <SelectUser
                      show={show}
                      data={data?.propertyOfficer}
                      stylesOptions={stylesOptions}
                      onChange={handleSupplyOfficer}
                      isDisabled={canReceivedAndSigned === false}
                      params={{
                        per_page: 1000,
                        ['roles[]']: 'Supply Officer',
                      }}
                    />
                  </div>
                </div>

                <Form.Label style={{ textAlign: 'center' }}>Property Officer</Form.Label>
              </Form.Group>
            </div>

            <div className={styles?.inspection}>
              <div
                className={styles?.dateInspected}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <Form.Label>Date Inspected:</Form.Label>
                <FormControl
                  type='date'
                  name='inspectedDate'
                  onChange={handleChange}
                  value={data?.inspectedDate}
                  // readOnly={isEdit === false}
                />
              </div>

              <Form.Check
                name='isVerified'
                onChange={changeCheckbox}
                // disabled={isEdit === false}
                checked={data?.isVerified === true}
                label='Inspected, verified and found OK as to quantity and specifications'
              />

              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <div className={styles?.input}>
                  {data?.inspectedBySignature && data?.inspectedBySignature !== null && (
                    <Image className={styles?.signatureImage} src={data?.inspectedBySignature} />
                  )}

                  <div className={styles?.requestedBy}>
                    <SelectUser
                      show={show}
                      data={data?.inspectedBy}
                      stylesOptions={stylesOptions}
                      // isDisabled={isEdit === false}
                      onChange={handleRequisitionOfficer}
                      params={{
                        per_page: 1000,
                        ['roles[]']: 'Requisition Officer',
                      }}
                    />
                  </div>
                </div>

                <Form.Label>Inspection Officer / Committee</Form.Label>
              </Form.Group>
            </div>
          </div>

          <div className={styles?.buttons}>
            <ConditionalRender
              condition={isCreate}
              renderIf={
                <Button
                  variant='success'
                  onClick={handleSave}
                  className={styles?.create}
                  disabled={createAirFormLogs?.isLoading}
                >
                  Create
                </Button>
              }
            />

            <ConditionalRender
              condition={isEdit}
              renderIf={
                <Button
                  variant='success'
                  onClick={handleUpdate}
                  className={styles?.create}
                  disabled={updateAirFormLogs?.isLoading}
                >
                  Update
                </Button>
              }
            />

            <Button onClick={handleClose} className={styles?.cancel} variant='danger'>
              Cancel
            </Button>
          </div>
        </div>
      </div>

      <Remarks
        data={data}
        showRemarks={showRemarks}
        handleClose={closeRemarks}
        handleChange={handleChange}
        // isLoading={declinedLogs?.isLoading}
        handleSubmitRemarks={handleSubmitRemarks}
      />
    </div>
  );
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    height: '38px',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '0',
    textAlign: 'center',
    fontStyle: 'italic',
    background: 'transparent',
    borderBottom: 'solid 1px #000',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '14px',
    background: '#F3F3F3',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

export default AirForm;
