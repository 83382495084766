/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { useState, useEffect } from 'react';

const SelectUser = ({ show = false, data, onChange, params, stylesOptions, ...rest }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (show) {
      const getUsers = async () => {
        try {
          axios.get('api/users', { params: params }).then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.id,
                label: `${dd?.attributes?.first_name} ${dd?.attributes?.last_name}`,
              };
            });

            setOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      getUsers();
    }
  }, [show]);

  return (
    <Select
      {...rest}
      value={data}
      options={options}
      onChange={onChange}
      styles={stylesOptions}
      placeholder='Enter Here'
    />
  );
};

export default SelectUser;
