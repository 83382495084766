/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createSAI = createAsyncThunk('createSAI', async (params, { rejectWithValue }) => {
  return await axios({
    data: params,
    method: 'post',
    url: `/api/sai`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getSAI = createAsyncThunk('getSAI', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    params: params,
    url: `/api/sai`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const updateSAI = createAsyncThunk(
  'updateSAI',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'put',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      url: `/api/sai/${id}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const approveSAIForm = createAsyncThunk(
  'approveSAIForm',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `/api/sai/approve/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const denySAIForm = createAsyncThunk(
  'denySAIForm',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      data: params,
      url: `/api/sai/reject/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const releaseItems = createAsyncThunk(
  'releaseItems',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `/api/sai/release/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
