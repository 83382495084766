/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FormControl, Table, Image } from 'react-bootstrap';

import { checkEmpty } from 'utils/validator';
import SelectUser from 'components/Layouts/SelectUser';
import FormNumber from 'components/GlobalUIs/FormNumber';
import ImageUpload from 'components/GlobalUIs/ImageUpload';
import { selectpurchaseOrder, createPO } from 'reducers/purchaseOrderReducer';

import styles from './PurchaseOrder.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';
import { ReactComponent as CirclePlus } from 'assets/svg/white circle plus.svg';
import { ReactComponent as PlusSVG } from 'assets/svg/plusSign.svg';

const PurchaseOrder = ({ show = false, setShowAddPO, tableData, handlePurchaseOrder }) => {
  const dispatch = useDispatch();

  const statePurchaseOrder = useSelector(selectpurchaseOrder);
  const { createPOLogs } = statePurchaseOrder;

  const [data, setData] = useState({
    lgu: '',
    pRNo: '',
    pOno: '',
    date: '',
    datePO: '',
    supplier: '',
    address: '',
    paymentTerm: '',
    totalAmount: '',
    deliveryTerm: '',
    dateOfDelivery: '',
    placeOfDelivery: '',
    certifiedCorrect: '',
    purchaseRequestId: '',
    modeOfProcurement: '',
    authorizedOfficial: '',
    accountOfficerPrintedName: '',
    accountOfficerSignature: null,
    accountOfficerSignatureDate: '',
    approvedSanggunianResolutionNo: '',
    items: [
      {
        id: uuidv4(),
        itemNo: '',
        quantity: '',
        unitOfIssue: '',
        itemDescription: '',
        estimatedUnitCost: '',
        estimatedCost: '',
      },
    ],
  });

  const [supplierOptions, setSupplierOptions] = useState([]);

  const handleClose = () => {
    setShowAddPO(false);

    setData({
      lgu: '',
      prNo: '',
      saiNo: '',
      address: '',
      alobsNo: '',
      prNoDate: '',
      supplier: '',
      saiNoDate: '',
      alobsNoDate: '',
      items: [
        {
          id: uuidv4(),
          itemNo: '',
          quantity: '',
          unitOfIssue: '',
          itemDescription: '',
          estimatedUnitCost: '',
          estimatedCost: '',
        },
      ],
      purpose: '',
      requestedBy: '',
      requestedDesignation: '',
      approvedBy: '',
      approvedDesignation: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const saveData = {
      items: data?.items,
      address: data?.address,
      payment_term: data?.paymentTerm,
      delivery_term: data?.deliveryTerm,
      supplier_name: data?.supplier,
      date_of_delivery: data?.dateOfDelivery,
      place_of_delivery: data?.placeOfDelivery,
      purchase_request_id: data?.purchaseRequestId,
      mode_of_procurement: data?.modeOfProcurement,
      total_amount_in_cash: data?.totalAmount,
    };

    console.log('saveData', saveData);

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData, ['items']);

    let arrCheck = false;
    saveData?.items?.forEach((dd) => {
      const result = checkEmpty(dd);

      if (result) {
        arrCheck = result;
      }
    });

    if (emptyCheck || arrCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    const formData = new FormData();
    formData.append('address', saveData?.address);
    formData.append('payment_term', saveData?.payment_term);
    formData.append('delivery_term', saveData?.delivery_term);
    formData.append('date_of_delivery', saveData?.date_of_delivery);
    formData.append('supplier_name', saveData?.supplier_name?.value);
    formData.append('place_of_delivery', saveData?.place_of_delivery);
    formData.append('purchase_request_id', saveData?.purchase_request_id);
    formData.append('mode_of_procurement', saveData?.mode_of_procurement);
    formData.append('total_amount_in_cash', saveData?.total_amount_in_cash);

    saveData?.items?.forEach((element, idx) => {
      formData.append(`items[${idx}]`, JSON.stringify(element));
    });

    try {
      const response = await dispatch(createPO(formData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(createPOLogs?.error);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseOrder();
        toast.info('Successfully Requested Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUserSelectRequestedBy = (e) => {
    setData((prev) => {
      return {
        ...prev,
        requestedBy: e,
        requestedByID: e?.id,
        requestedDesignation: e?.attributes?.role,
        requestedBySignature: e?.attributes?.signature,
      };
    });
  };

  const handleSelectedItem = (e) => (name) => {
    setData((prev) => {
      return {
        ...prev,
        [name]: e,
      };
    });
  };

  const handleSelectedSupplier = (e) => (name) => {
    setData((prev) => {
      return {
        ...prev,
        [name]: e,
        address: e?.attributes?.supplier_address,
      };
    });
  };

  useEffect(() => {
    if (show && tableData) {
      const getSuppliers = async () => {
        try {
          axios.get('api/supplier').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.supplier_name,
                label: dd?.attributes?.supplier_name,
              };
            });

            setSupplierOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      getSuppliers();

      try {
        axios
          .get('api/users', {
            params: {
              per_page: 1000,
              ['roles[0]']: 'Account Officer',
            },
          })
          .then((response) => {
            const filteredRequestedByData = response?.data?.data?.find(
              (dd) => +dd?.id === +tableData?.approved_by_id,
            );

            const items = tableData?.items?.map((dd) => ({
              ...dd,
              itemNo: dd?.attributes?.item_no,
              quantity: dd?.attributes?.quantity,
              unitOfIssue: dd?.attributes?.unit_of_issue,
              estimatedCost: dd?.attributes?.estimated_cost,
              itemDescription: dd?.attributes?.item_description,
              estimatedUnitCost: dd?.attributes?.estimated_unit_cost,
            }));

            const totalCost = tableData?.items?.reduce((dd, e) => {
              return +dd + +e?.attributes?.estimated_cost;
            }, 0);

            console.log('totalCost', totalCost);

            setData((prev) => ({
              ...prev,
              items: items,
              lgu: tableData?.lgu,
              pRNo: tableData?.pr_no,
              totalAmount: totalCost,
              purchaseRequestId: tableData?.id,
              accountOfficerSignatureDate: tableData?.approved_by_date,
              accountOfficerSignature: filteredRequestedByData?.attributes?.signature,
              accountOfficerPrintedName: {
                label: tableData?.attributes?.approved_by,
                value: tableData?.attributes?.approved_by,
              },
            }));
          });
      } catch (error) {
        console.log('Error', error);
      }
    }
  }, [show, tableData]);

  console.log('LOGS', createPOLogs);

  return (
    <div className={`${styles?.addItem} ${show ? styles?.active : ''}`}>
      <div className={styles?.padding}>
        <div className={styles?.header}>
          <p>PURCHASE ORDER</p>

          <CloseSVG className={styles?.close} onClick={handleClose} />
        </div>

        <div className={styles?.body}>
          <hr className={styles?.hr} />

          <Form.Group className={styles?.centerGroup}>
            <FormControl readOnly name='lgu' value={data?.lgu} placeholder='Enter Here' />
            <Form.Label>LGU</Form.Label>
          </Form.Group>

          <div className={styles?.formHeader}>
            <div className={styles?.col}>
              {/* <Form.Group className={styles?.group}>
                <Form.Label>Supplier</Form.Label>
                <FormControl
                  name='supplier'
                  value={data?.supplier}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group> */}

              <Form.Group className={styles?.group}>
                <Form.Label>Supplier</Form.Label>
                <div className={styles?.selectContainer}>
                  <Select
                    styles={stylesOptions}
                    value={data?.supplier}
                    placeholder='Enter Here'
                    options={supplierOptions}
                    onChange={(e) => handleSelectedSupplier(e)('supplier')}
                    // isDisabled={data?.status !== 'for approval' || canApproveRequest === true}
                  />
                </div>
              </Form.Group>

              <Form.Group className={`${styles?.group} ${styles?.centered}`}>
                <Form.Label>Address</Form.Label>
                <FormControl
                  readOnly
                  name='address'
                  value={data?.address}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>P.O. No.:</Form.Label>
                <FormControl
                  disabled
                  name='pOno'
                  value={data?.pOno}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Date:</Form.Label>
                <FormControl
                  disabled
                  name='datePO'
                  value={data?.datePO}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Mode of Procurement:</Form.Label>
                <FormControl
                  onChange={handleChange}
                  name='modeOfProcurement'
                  placeholder='Enter Here'
                  value={data?.modeOfProcurement}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>PR No./s:</Form.Label>
                <FormControl
                  disabled
                  name='pRNo'
                  value={data?.pRNo}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
          </div>

          <div className={styles?.terms}>
            <h6>Gentlemen:</h6>
            <p>
              Please furnish this office the following articles subject to the terms and conditions
              contained herein:
            </p>
          </div>

          <div className={`${styles?.formHeader} ${styles?.paddingTop}`}>
            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Place of Delivery:</Form.Label>
                <FormControl
                  name='placeOfDelivery'
                  value={data?.placeOfDelivery}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Date of Delivery:</Form.Label>
                <FormControl
                  type='date'
                  name='dateOfDelivery'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.dateOfDelivery}
                />
              </Form.Group>
            </div>
            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>Delivery Term:</Form.Label>
                <FormControl
                  name='deliveryTerm'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.deliveryTerm}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Payment Term:</Form.Label>
                <FormControl
                  name='paymentTerm'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.paymentTerm}
                />
              </Form.Group>
            </div>
          </div>

          <div className={styles?.form}>
            <Table>
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Quantity</th>
                  <th>Unit of Issue</th>
                  <th>Item Description</th>
                  <th>Estimated Unit Cost</th>
                  <th>Estimated Cost</th>
                </tr>
              </thead>
              <tbody>
                {data?.items &&
                  data?.items?.map((dd) => {
                    return (
                      <tr key={dd?.id}>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemNo'
                            value={dd?.itemNo}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='quantity'
                            value={dd?.quantity}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='unitOfIssue'
                            value={dd?.unitOfIssue}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemDescription'
                            placeholder='Enter Here'
                            value={dd?.itemDescription}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='estimatedUnitCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedUnitCost}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='estimatedCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedCost}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          <div className={styles?.totalAmount}>
            <Form.Group className={styles?.group}>
              <Form.Label>Total Amount in Cash:</Form.Label>
              <Form.Control
                readOnly
                name='totalAmount'
                onChange={handleChange}
                placeholder='Enter Here'
                value={data?.totalAmount}
              />
            </Form.Group>
          </div>

          <div className={styles?.failure}>
            <p>
              In case of failure to make the full delivery within the time specified above, a
              penalty of one-tenth (1/10) of one percent for every day of delay shall be imposed.
            </p>
          </div>

          <div className={styles?.signatures}>
            <div className={styles?.left}>
              <p>Conforme:</p>

              {/* <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <Form.Control
                  readOnly
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='accountOfficerPrintedName'
                  value={data?.accountOfficerPrintedName}
                />
                <Form.Label>Signature over printed name</Form.Label>
              </Form.Group> */}

              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <div className={styles?.input}>
                  {data?.accountOfficerSignature && data?.accountOfficerSignature !== null && (
                    <Image className={styles?.signatureImage} src={data?.accountOfficerSignature} />
                  )}

                  <div className={styles?.requestedBy}>
                    <SelectUser
                      isDisabled
                      show={show}
                      stylesOptions={stylesOptions}
                      data={data?.accountOfficerPrintedName}
                      onChange={handleUserSelectRequestedBy}
                      params={{
                        per_page: 1000,
                        ['roles[]']: 'Account Officer',
                      }}
                    />
                  </div>
                </div>

                <Form.Label>Signature over printed name</Form.Label>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Control
                  readOnly
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='accountOfficerSignatureDate'
                  value={data?.accountOfficerSignatureDate}
                />
                <Form.Label>Date</Form.Label>
              </Form.Group>
            </div>
            <div className={styles?.right}>
              <p>Conforme:</p>

              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <Form.Control
                  readOnly
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='authorizedOfficial'
                  value={data?.authorizedOfficial}
                />
                <Form.Label>Authorized Official</Form.Label>
              </Form.Group>
            </div>
          </div>

          <div className={styles?.failure}>
            <p>
              (In case of Negotiated Purchase pursuant to Section 369 (a) of RA 7160, this portion
              must be accomplished.)
            </p>
          </div>

          <div className={styles?.resolution}>
            <Form.Group className={styles?.group}>
              <Form.Label>Approved per Sanggunian Resolution No.:</Form.Label>
              <Form.Control
                disabled
                onChange={handleChange}
                placeholder='Enter Here'
                name='approvedSanggunianResolutionNo'
                value={data?.approvedSanggunianResolutionNo}
              />
            </Form.Group>
          </div>

          <div className={styles?.negotiate}>
            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Certified Correct:</Form.Label>
                <FormControl
                  disabled
                  name='certifiedCorrect'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.certifiedCorrect}
                />
              </Form.Group>
            </div>
            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>Date:</Form.Label>
                <FormControl
                  disabled
                  name='date'
                  value={data?.date}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>

      <div className={styles?.buttons}>
        <Button disabled={createPOLogs?.isLoading} onClick={handleSave} className={styles?.save}>
          Save PO
        </Button>
        <Button onClick={handleClose} className={styles?.cancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    height: '38px',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '0',
    textAlign: 'center',
    fontStyle: 'italic',
    background: 'transparent',
    borderBottom: 'solid 1px #000',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '14px',
    background: '#F3F3F3',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

export default PurchaseOrder;
