/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAIRList = createAsyncThunk('getAIRList', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    params: params,
    url: `/api/air`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const createAirForm = createAsyncThunk(
  'createAirForm',
  async (params, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `/api/air`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateAirForm = createAsyncThunk(
  'updateAirForm',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'put',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      url: `/api/air/${id}?include[]=item`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const approveAIR = createAsyncThunk(
  'approveAIR',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'put',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      url: `api/air/approve/${id}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// export const approveRequestForm = createAsyncThunk(
//   'approveRequestForm',
//   async (id, { rejectWithValue }) => {
//     return await axios({
//       method: 'get',
//       url: `/api/purchase-request/approve/${id}`,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );

// export const denyRequestForm = createAsyncThunk(
//   'denyRequestForm',
//   async ({ params, id }, { rejectWithValue }) => {
//     return await axios({
//       method: 'put',
//       data: params,
//       url: `/api/purchase-request/reject/${id}`,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );
