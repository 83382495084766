/* eslint-disable newline-per-chained-call */
/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Table, Button, Container, FormControl } from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import TablePagination from 'components/GlobalUIs/Pagination';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import { selectpurchaseOrder, getPurchaseOrder } from 'reducers/purchaseOrderReducer';
import PurchaseOrderModal from './components/Modals/PurchaseOrder/PurchaseOrder';

// Assets
import styles from './index.module.scss';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as LucideSVG } from 'assets/svg/lucide_view.svg';
import { ReactComponent as ExportSVG } from 'assets/svg/icon-park_export.svg';

const sampleData = [
  {
    id: 1,
    transactionID: '00-012',
    transactionType: 'Purchase Order',
    transactBy: 'Juan Dela Cruz',
    transactionDate: 'July 19, 2023',
    time: '12:47 PM',
    status: 'Approved',
  },
  {
    id: 2,
    transactionID: '00-012',
    transactionType: 'Purchase Order',
    transactBy: 'Juan Dela Cruz',
    transactionDate: 'July 19, 2023',
    time: '12:47 PM',
    status: 'Reject',
  },
  {
    id: 3,
    transactionID: '00-012',
    transactionType: 'Purchase Order',
    transactBy: 'Juan Dela Cruz',
    transactionDate: 'July 19, 2023',
    time: '12:47 PM',
    status: 'Reject',
  },
  {
    id: 4,
    transactionID: '00-012',
    transactionType: 'Purchase Order',
    transactBy: 'Juan Dela Cruz',
    transactionDate: 'July 19, 2023',
    time: '12:47 PM',
    status: 'Approved',
  },
  {
    id: 5,
    transactionID: '00-012',
    transactionType: 'Purchase Order',
    transactBy: 'Juan Dela Cruz',
    transactionDate: 'July 19, 2023',
    time: '12:47 PM',
    status: 'Approved',
  },
];

// Main component
const PurchaseOrderReport = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const purchaseOrderState = useSelector(selectpurchaseOrder);
  const { meta, logs } = purchaseOrderState;

  const [data, setData] = useState([]);
  const [showViewOrder, setShowViewOrder] = useState({
    data: null,
    show: false,
  });

  const [filter, setFilter] = useState({
    to: '',
    page: 1,
    from: '',
    search: '',
    per_page: 5,
    sort_status: '',
  });

  const [showAdd, setShowAdd] = useState(false);
  const [showRequest, setShowRequest] = useState(false);

  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    const newObj = {
      to: filter?.to,
      q: filter?.search,
      sort_status: value,
      page: filter?.page,
      from: filter?.from,
      ['include[2]']: 'air',
      ['include[0]']: 'item',
      per_page: filter?.per_page,
      ['include[1]']: 'purchase_request',
      filter_status: filter?.filter_status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const separateDateAndTime = (dateTimeString) => {
    if (!dateTimeString) return;

    const dateObject = new Date(dateTimeString);

    const month = dateObject.toLocaleString('default', { month: 'short' });
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

    return { date: dateString, time: timeString };
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        to: filter?.to,
        from: filter?.from,
        ['include[2]']: 'air',
        ['include[0]']: 'item',
        per_page: filter?.per_page,
        sort_status: filter?.sort_status,
        ['include[1]']: 'purchase_request',
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const tryCatch = async (params) => {
    setData([]);

    try {
      const response = await dispatch(getPurchaseOrder(params));

      const newData = response?.payload?.data?.map((dd) => {
        let filteredItems = [];
        let filteredPR = [];

        if (dd?.relationships?.item?.data) {
          filteredItems = dd?.relationships?.item?.data?.map((item) => {
            const result = response?.payload?.included?.find(
              (ddd) => +ddd?.id === +item?.id && ddd.type === 'purchase_request_items',
            );

            return result;
          });
        }

        if (dd?.relationships?.item?.data) {
          filteredPR = dd?.relationships?.purchase_request?.data?.map((item) => {
            const result = response?.payload?.included?.find(
              (ddd) => +ddd?.id === +item?.id && ddd.type === 'purchase_requests',
            );

            return result;
          });
        }

        const { date, time } = separateDateAndTime(dd?.attributes?.created_at);

        return {
          ...dd,
          id: dd?.id,
          time: time,
          ...dd?.attributes,
          requestID: dd?.id,
          dateRequested: date,
          items: filteredItems,
          transactionID: dd?.id,
          status: dd?.attributes?.status,
          purchaseRequestData: filteredPR,
          transactionType: 'Purchase Order',
          department: dd?.attributes?.department,
          requestBy: dd?.attributes?.requested_by,
          approvedBy: dd?.attributes?.approved_by,
          transactBy: dd?.attributes?.requested_by,
          transactionDate: dd?.attributes?.created_at,
        };
      });

      setData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleOrderRequest = () => {
    const unfinalParams = {
      to: filter?.to,
      q: filter?.search,
      page: filter?.page,
      from: filter?.from,
      ['include[2]']: 'air',
      ['include[0]']: 'item',
      per_page: filter?.per_page,
      sort_status: filter?.sort_status,
      ['include[1]']: 'purchase_request',
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: filter?.q,
      to: filter?.to,
      from: filter?.from,
      ['include[2]']: 'air',
      ['include[0]']: 'item',
      sort_status: filter?.sort_status,
      ['include[1]']: 'purchase_request',
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleDates = (e) => {
    const { name, value } = e.target;

    const _dateRange = {
      to: filter?.to,
      from: filter?.from,
    };

    _dateRange[name] = value;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      if (_dateRange?.from !== '' && _dateRange?.to !== '') {
        setFilter((prev) => ({ ...prev, page: 1 }));

        const newObj = {
          page: 1,
          q: filter?.search,
          to: _dateRange?.to,
          ['include[2]']: 'air',
          ['include[0]']: 'item',
          from: _dateRange?.from,
          per_page: filter?.per_page,
          sort_status: filter?.sort_status,
          ['include[1]']: 'purchase_request',
          filter_status: filter?.filter_status,
        };

        const params = objectCleaner(newObj);
        tryCatch(params);
      }
    }, 1000);
  };

  useEffect(() => {
    handleOrderRequest();
  }, []);

  return (
    <PrivateLayout pageTitle='Purchase Order Report'>
      <div className={`${styles?.inventory} ${showAdd ? styles?.show : ''}`}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <h5>Reports</h5>
                  </div>

                  <div className={styles?.filter}>
                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='search'
                        placeholder='Search'
                        value={filter?.search}
                        onChange={handleSearch}
                      />
                    </Form.Group>

                    <div className={styles?.from}>
                      <p className={styles?.title}>From</p>

                      <FormControl
                        name='from'
                        type='date'
                        value={filter?.from}
                        onChange={handleDates}
                        className={styles?.fromInput}
                        max={new Date().toISOString().slice(0, 10)}
                      />
                    </div>

                    <div className={styles?.to}>
                      <p className={styles?.title}>To</p>

                      <FormControl
                        name='to'
                        type='date'
                        value={filter?.to}
                        min={filter?.from}
                        className={styles?.toInput}
                        max={new Date().toISOString().slice(0, 10)}
                        onChange={(e) => {
                          if (filter?.from !== '') {
                            handleDates(e);
                            return;
                          }

                          toast.warning('Please select from first');
                        }}
                      />
                    </div>

                    <div className={styles?.selectContainer}>
                      <Form.Select
                        name='sort_status'
                        onChange={handleSort}
                        className={styles?.select}
                        value={filter?.sort_status}
                      >
                        <option value='' hidden>
                          Sort By
                        </option>
                        <option value='for approval'>For Approval</option>
                        <option value='approved'>Approved</option>
                        <option value='rejected'>Rejected</option>
                      </Form.Select>
                    </div>

                    <Button className={styles?.exportAll}>
                      <ExportSVG /> Export All
                    </Button>
                  </div>
                </div>

                <div className={styles?.body}>
                  <Table responsive className={styles?.processTable}>
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>Type of Transaction</th>
                        <th>Transact By</th>
                        <th>Transaction Date</th>
                        <th>Time</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length >= 1 &&
                        data.map((dd) => (
                          <tr key={dd?.id}>
                            <td>{dd?.transactionID}</td>
                            <td>
                              <p
                                className={
                                  dd?.transactionType === 'Purchase Order'
                                    ? styles?.PurchaseOrder
                                    : ''
                                }
                              >
                                {dd?.transactionType}
                              </p>
                            </td>
                            <td>{dd?.transactBy}</td>
                            <td>{dd?.transactionDate}</td>
                            <td>{dd?.time}</td>
                            <td>
                              <span
                                className={`${
                                  dd?.status === 'For Approval'
                                    ? styles?.forApproval
                                    : styles[dd?.status]
                                }`}
                              >
                                {dd?.status}
                              </span>
                            </td>
                            <td className={styles?.actions}>
                              <Button
                                onClick={() => {
                                  setShowViewOrder({
                                    data: dd,
                                    show: true,
                                  });
                                }}
                                className={styles?.viewPO}
                              >
                                <LucideSVG />
                                View
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10}>
                          <TablePagination
                            meta={meta}
                            setParams={setFilter}
                            loading={logs?.isLoading}
                            handlePaginationClick={handlePaginationClick}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </div>

              <PurchaseOrderModal
                show={showViewOrder?.show}
                tableData={showViewOrder?.data}
                setShowViewOrder={setShowViewOrder}
                handleOrderRequest={handleOrderRequest}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default PurchaseOrderReport;
