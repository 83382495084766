/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

import './index.scss';

const SignaturePad = ({ show, handleClose, name, setData }) => {
  const [sign, setSign] = useState();

  const handleSave = () => {
    const img = sign.getTrimmedCanvas().toDataURL('image/png');

    setData((prev) => {
      return {
        ...prev,
        [name]: img,
      };
    });

    handleClose();
  };

  return (
    <Modal centered show={show} className='assignPermission' onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Sign Signature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='signature-body'>
          <SignatureCanvas
            penColor='black'
            ref={(data) => setSign(data)}
            canvasProps={{ className: 'sigCanvas' }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='signature-buttons'>
        <Button onClick={handleSave} className='save'>
          Save
        </Button>
        <Button onClick={handleClose} className='cancel'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SignaturePad;
