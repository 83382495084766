/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { createAirForm, updateAirForm, approveAIR, getAIRList } from './thunks/airFormThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  list: [],
  meta: null,
  logs: initialLog,
  createAirFormLogs: initialLog,
  updateAirFormLogs: initialLog,
  approveAirFormLogs: initialLog,
};

const slice = createSlice({
  name: 'airForm',
  initialState,
  reducers: {},
  extraReducers: {
    [getAIRList.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.list = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getAIRList.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getAIRList.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [createAirForm.fulfilled]: (state, action) => {
      state.createAirFormLogs.isSuccess = true;
      state.createAirFormLogs.isLoading = false;
    },
    [createAirForm.pending]: (state) => {
      state.createAirFormLogs.error = '';
      state.createAirFormLogs.isFailed = false;
      state.createAirFormLogs.isLoading = true;
      state.createAirFormLogs.isSuccess = false;
    },
    [createAirForm.rejected]: (state, action) => {
      state.createAirFormLogs.isFailed = true;
      state.createAirFormLogs.isLoading = false;
      state.createAirFormLogs.error = action?.payload?.errors[0]?.detail;
    },
    [updateAirForm.fulfilled]: (state, action) => {
      state.updateAirFormLogs.isSuccess = true;
      state.updateAirFormLogs.isLoading = false;
    },
    [updateAirForm.pending]: (state) => {
      state.updateAirFormLogs.error = '';
      state.updateAirFormLogs.isFailed = false;
      state.updateAirFormLogs.isLoading = true;
      state.updateAirFormLogs.isSuccess = false;
    },
    [updateAirForm.rejected]: (state, action) => {
      state.updateAirFormLogs.isFailed = true;
      state.updateAirFormLogs.isLoading = false;
      state.updateAirFormLogs.error = action?.payload?.errors[0]?.detail;
    },
    [approveAIR.fulfilled]: (state, action) => {
      state.approveAirFormLogs.isSuccess = true;
      state.approveAirFormLogs.isLoading = false;
    },
    [approveAIR.pending]: (state) => {
      state.approveAirFormLogs.error = '';
      state.approveAirFormLogs.isFailed = false;
      state.approveAirFormLogs.isLoading = true;
      state.approveAirFormLogs.isSuccess = false;
    },
    [approveAIR.rejected]: (state, action) => {
      state.approveAirFormLogs.isFailed = true;
      state.approveAirFormLogs.isLoading = false;
      state.approveAirFormLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: airFormActions, reducer: airFormReducer } = slice;

export const { updateUserinfo } = airFormActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.airForm || initialState;

export const selectAIR = createSelector([selectDomain], (airFormState) => airFormState);

/* --------------------------------------------------------------------------------- */

export { createAirForm, updateAirForm, approveAIR, getAIRList };
