import React from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';

import styles from './index.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

const Remarks = ({
  data,
  isLoading,
  showRemarks,
  handleClose,
  handleChange,
  handleSubmitRemarks,
}) => {
  return (
    <div className={`${styles?.remarks} ${showRemarks ? styles?.active : ''}`}>
      <div className={styles?.header}>
        <p>Reject Remarks</p>

        <CloseSVG className={styles?.close} onClick={handleClose} />
      </div>

      <div className={styles?.body}>
        <Form.Group className={styles?.group}>
          <FormControl
            rows={5}
            name='remarks'
            as={'textarea'}
            value={data?.remarks}
            onChange={handleChange}
            placeholder='Enter Here'
          />
        </Form.Group>

        <Button disabled={isLoading} onClick={handleSubmitRemarks} className={styles?.submit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Remarks;
