/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
/* eslint-disable newline-per-chained-call */

// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Table, Button, Container, FormControl } from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import ConditionalRender from 'components/ConditionalRender';
import TablePagination from 'components/GlobalUIs/Pagination';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import { checkStringPermission } from 'helpers/filteredPermissions';
import ViewRequest from './components/Modals/ViewRequest/ViewRequest';
import RequestItem from './components/Modals/RequestItem/RequestItem';
import PurchaseOrder from './components/Modals/PurchaseOrder/PurchaseOrder';
import { getPurchaseRequest, selectPurchaseRequest } from 'reducers/purchaseRequestReducer';

// Assets
import styles from './index.module.scss';
import { ReactComponent as ViewSVG } from 'assets/svg/view.svg';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as FileIconSVG } from 'assets/svg/file icon.svg';
import { ReactComponent as LucideSVG } from 'assets/svg/lucide_view.svg';
import { ReactComponent as CirclePlus } from 'assets/svg/circle plus.svg';

// Main component
const PurchaseRequest = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const purchaseRequestState = useSelector(selectPurchaseRequest);
  const { meta, logs } = purchaseRequestState;

  const [data, setData] = useState([]);
  const [showRequest, setShowRequest] = useState(false);
  const [showAddPO, setShowAddPO] = useState({ data: null, show: false });
  const [showViewRequest, setShowViewRequest] = useState({ data: null, show: false });
  const [filter, setFilter] = useState({
    to: '',
    page: 1,
    from: '',
    search: '',
    per_page: 5,
    sort_status: '',
  });

  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    const newObj = {
      to: filter?.to,
      q: filter?.search,
      sort_status: value,
      page: filter?.page,
      from: filter?.from,
      ['include[]']: 'item',
      per_page: filter?.per_page,
      filter_status: filter?.filter_status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        to: filter?.to,
        from: filter?.from,
        ['include[]']: 'item',
        per_page: filter?.per_page,
        sort_status: filter?.sort_status,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const handlePurchaseRequest = () => {
    const unfinalParams = {
      to: filter?.to,
      q: filter?.search,
      page: filter?.page,
      from: filter?.from,
      ['include[]']: 'item',
      per_page: filter?.per_page,
      sort_status: filter?.sort_status,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: filter?.q,
      to: filter?.to,
      from: filter?.from,
      ['include[]']: 'item',
      sort_status: filter?.sort_status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleDates = (e) => {
    const { name, value } = e.target;

    const _dateRange = {
      to: filter?.to,
      from: filter?.from,
    };

    _dateRange[name] = value;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      if (_dateRange?.from !== '' && _dateRange?.to !== '') {
        setFilter((prev) => ({ ...prev, page: 1 }));

        const newObj = {
          page: 1,
          q: filter?.search,
          to: _dateRange?.to,
          ['include[]']: 'item',
          from: _dateRange?.from,
          per_page: filter?.per_page,
          sort_status: filter?.sort_status,
          filter_status: filter?.filter_status,
        };

        const params = objectCleaner(newObj);
        tryCatch(params);
      }
    }, 1000);
  };

  const separateDateAndTime = (dateTimeString) => {
    if (!dateTimeString) return;

    const dateObject = new Date(dateTimeString);

    const month = dateObject.toLocaleString('default', { month: 'short' });
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

    return { date: dateString, time: timeString };
  };

  const tryCatch = async (params) => {
    setData([]);

    try {
      const response = await dispatch(getPurchaseRequest(params));
      const newData = response?.payload?.data?.map((dd) => {
        let filteredItems = [];

        if (dd?.relationships?.item?.data) {
          filteredItems = dd?.relationships?.item?.data?.map((item) => {
            const result = response?.payload?.included?.find(
              (ddd) => +ddd?.id === +item?.id && ddd.type === 'purchase_request_items',
            );

            return result;
          });
        }

        const { date, time } = separateDateAndTime(dd?.attributes?.created_at);

        return {
          ...dd,
          ...dd?.attributes,
          id: dd?.id,
          time: time,
          requestID: dd?.id,
          dateRequested: date,
          items: filteredItems,
          status: dd?.attributes?.status,
          department: dd?.attributes?.department,
          requestBy: dd?.attributes?.requested_by,
          approvedBy: dd?.attributes?.approved_by,
        };
      });

      setData(newData);
    } catch (error) {
      console.log('error', error);
      toast.error('Something went wrong.');
    }
  };

  useEffect(() => {
    handlePurchaseRequest();
  }, []);

  const canRequestForm = checkStringPermission('can create purchase request');
  const canApproveRequest = checkStringPermission('can approve purchase request');

  return (
    <PrivateLayout pageTitle='Purchase Request'>
      <div className={styles?.inventory}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <h5>Purchase Request List</h5>
                  </div>

                  <div className={styles?.filter}>
                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='search'
                        placeholder='Search'
                        value={filter?.search}
                        onChange={handleSearch}
                      />
                    </Form.Group>

                    <div className={styles?.from}>
                      <p className={styles?.title}>From</p>

                      <FormControl
                        name='from'
                        type='date'
                        value={filter?.from}
                        onChange={handleDates}
                        className={styles?.fromInput}
                        max={new Date().toISOString().slice(0, 10)}
                      />
                    </div>

                    <div className={styles?.to}>
                      <p className={styles?.title}>To</p>

                      <FormControl
                        name='to'
                        type='date'
                        value={filter?.to}
                        min={filter?.from}
                        className={styles?.toInput}
                        max={new Date().toISOString().slice(0, 10)}
                        onChange={(e) => {
                          if (filter?.from !== '') {
                            handleDates(e);
                            return;
                          }

                          toast.warning('Please select from first');
                        }}
                      />
                    </div>

                    <div className={styles?.selectContainer}>
                      <Form.Select
                        name='sort_status'
                        onChange={handleSort}
                        className={styles?.select}
                        value={filter?.sort_status}
                      >
                        <option value='' hidden>
                          Sort By
                        </option>
                        <option value='for approval'>For Approval</option>
                        <option value='approved'>Approved</option>
                      </Form.Select>
                    </div>

                    <ConditionalRender
                      condition={canRequestForm}
                      renderIf={
                        <Button
                          onClick={() => {
                            setShowRequest(true);
                          }}
                          variant='success'
                          className={styles?.requestSettings}
                        >
                          <CirclePlus />
                          Request Form
                        </Button>
                      }
                    />
                  </div>
                </div>

                <div className={styles?.body}>
                  <Table responsive className={styles?.processTable}>
                    <thead>
                      <tr>
                        <th>Request ID</th>
                        <th>Request By</th>
                        <th>Date Requested</th>
                        <th>Time</th>
                        <th>Department</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length >= 1 &&
                        data.map((dd) => (
                          <tr key={dd?.id}>
                            <td>{dd?.requestID}</td>
                            <td>{dd?.requestBy}</td>
                            <td>{dd?.dateRequested}</td>
                            <td>{dd?.time}</td>
                            <td>{dd?.department}</td>
                            <td>
                              <span
                                className={
                                  dd?.status === 'for approval'
                                    ? styles?.forApproval
                                    : styles[dd?.status]
                                }
                              >
                                {dd?.status}
                              </span>
                            </td>
                            <ConditionalRender
                              condition={canApproveRequest}
                              renderIf={
                                <td className={styles?.actions}>
                                  <Button
                                    className={styles?.actionPR}
                                    onClick={() => {
                                      setShowViewRequest({
                                        data: dd,
                                        show: true,
                                      });
                                    }}
                                  >
                                    <LucideSVG />
                                    View Request
                                  </Button>
                                </td>
                              }
                              renderElse={
                                <td className={styles?.actions}>
                                  {dd?.status === 'approved' && (
                                    <>
                                      <Button
                                        className={styles?.viewPR}
                                        onClick={() => {
                                          setShowViewRequest({
                                            data: dd,
                                            show: true,
                                          });
                                        }}
                                      >
                                        <ViewSVG />
                                        View PR
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          setShowAddPO({
                                            data: dd,
                                            show: true,
                                          });
                                        }}
                                        className={styles?.createPO}
                                      >
                                        <FileIconSVG /> Create PO
                                      </Button>
                                    </>
                                  )}

                                  {dd?.status === 'for approval' && (
                                    <Button
                                      className={styles?.viewPR}
                                      onClick={() => {
                                        setShowViewRequest({
                                          data: dd,
                                          show: true,
                                        });
                                      }}
                                    >
                                      <ViewSVG />
                                      View / Edit
                                    </Button>
                                  )}

                                  {dd?.status === 'rejected' && (
                                    <Button
                                      className={styles?.rejectPR}
                                      onClick={() => {
                                        setShowViewRequest({
                                          data: dd,
                                          show: true,
                                        });
                                      }}
                                    >
                                      <ViewSVG />
                                      View PR
                                    </Button>
                                  )}
                                </td>
                              }
                            />
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10}>
                          <TablePagination
                            meta={meta}
                            setParams={setFilter}
                            loading={logs?.isLoading}
                            handlePaginationClick={handlePaginationClick}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </div>

              <RequestItem
                show={showRequest}
                setShowRequest={setShowRequest}
                handlePurchaseRequest={handlePurchaseRequest}
              />

              <ViewRequest
                show={showViewRequest?.show}
                tableData={showViewRequest?.data}
                setShowViewRequest={setShowViewRequest}
                handlePurchaseRequest={handlePurchaseRequest}
              />

              <PurchaseOrder
                show={showAddPO?.show}
                tableData={showAddPO?.data}
                setShowAddPO={setShowAddPO}
                handlePurchaseOrder={handlePurchaseRequest}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default PurchaseRequest;
