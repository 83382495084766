/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  requestForm,
  denyRequestForm,
  updateRequestForm,
  approveRequestForm,
  getPurchaseRequest,
} from './thunks/purchaseRequestThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  users: [],
  meta: null,
  logs: initialLog,
  requestFormLogs: initialLog,
  updateRequestFormLogs: initialLog,
  approveLogs: initialLog,
  declinedLogs: initialLog,
};

const slice = createSlice({
  name: 'purchaseRequest',
  initialState,
  reducers: {},
  extraReducers: {
    [getPurchaseRequest.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.users = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getPurchaseRequest.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getPurchaseRequest.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [requestForm.fulfilled]: (state, action) => {
      state.requestFormLogs.isSuccess = true;
      state.requestFormLogs.isLoading = false;
    },
    [requestForm.pending]: (state) => {
      state.requestFormLogs.error = '';
      state.requestFormLogs.isFailed = false;
      state.requestFormLogs.isLoading = true;
      state.requestFormLogs.isSuccess = false;
    },
    [requestForm.rejected]: (state, action) => {
      state.requestFormLogs.isFailed = true;
      state.requestFormLogs.isLoading = false;
      state.requestFormLogs.error = action?.payload?.errors[0]?.detail;
    },
    [updateRequestForm.fulfilled]: (state, action) => {
      state.updateRequestFormLogs.isSuccess = true;
      state.updateRequestFormLogs.isLoading = false;
    },
    [updateRequestForm.pending]: (state) => {
      state.updateRequestFormLogs.error = '';
      state.updateRequestFormLogs.isFailed = false;
      state.updateRequestFormLogs.isLoading = true;
      state.updateRequestFormLogs.isSuccess = false;
    },
    [updateRequestForm.rejected]: (state, action) => {
      state.updateRequestFormLogs.isFailed = true;
      state.updateRequestFormLogs.isLoading = false;
      state.updateRequestFormLogs.error = action?.payload?.errors[0]?.detail;
    },
    [approveRequestForm.fulfilled]: (state, action) => {
      state.approveLogs.isSuccess = true;
      state.approveLogs.isLoading = false;
    },
    [approveRequestForm.pending]: (state) => {
      state.approveLogs.error = '';
      state.approveLogs.isFailed = false;
      state.approveLogs.isLoading = true;
      state.approveLogs.isSuccess = false;
    },
    [approveRequestForm.rejected]: (state, action) => {
      state.approveLogs.isFailed = true;
      state.approveLogs.isLoading = false;
      state.approveLogs.error = action?.payload?.errors[0]?.detail;
    },
    [denyRequestForm.fulfilled]: (state, action) => {
      state.declinedLogs.isSuccess = true;
      state.declinedLogs.isLoading = false;
    },
    [denyRequestForm.pending]: (state) => {
      state.declinedLogs.error = '';
      state.declinedLogs.isFailed = false;
      state.declinedLogs.isLoading = true;
      state.declinedLogs.isSuccess = false;
    },
    [denyRequestForm.rejected]: (state, action) => {
      state.declinedLogs.isFailed = true;
      state.declinedLogs.isLoading = false;
      state.declinedLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: purchaseRequestActions, reducer: purchaseRequestReducer } = slice;

export const { updateUserinfo } = purchaseRequestActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.purchaseRequest || initialState;

export const selectPurchaseRequest = createSelector(
  [selectDomain],
  (purchaseRequestState) => purchaseRequestState,
);

/* --------------------------------------------------------------------------------- */

export { getPurchaseRequest, requestForm, updateRequestForm, approveRequestForm, denyRequestForm };
