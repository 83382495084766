/* eslint-disable multiline-comment-style */
/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FormControl, Table, Image } from 'react-bootstrap';

import Remarks from '../Remarks';
import { checkEmpty } from 'utils/validator';
import { selectAuth } from 'reducers/authReducer';
import { objectCleaner } from 'utils/objectCleaner';
import SelectUser from 'components/Layouts/SelectUser';
import { checkStringPermission } from 'helpers/filteredPermissions';
import {
  updateSAI,
  selectSai,
  denySAIForm,
  releaseItems,
  approveSAIForm,
} from 'reducers/saiReducer';

import styles from './ViewSiaForm.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';
import { ReactComponent as CirclePlus } from 'assets/svg/add.svg';

const ViewSiaForm = ({ show = false, setShowSIA, tableData, handleRefreshData }) => {
  const dispatch = useDispatch();

  const stateSAI = useSelector(selectSai);
  const { updateLogs, declinedLogs, approveLogs, releaseLogs } = stateSAI;

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const [data, setData] = useState({
    lgu: {},
    code: '',
    number: '',
    office: {},
    noDate: '',
    division: {},
    purpose: '',
    inquiredByID: '',
    providedByID: '',
    inquiredByName: '',
    providedByName: '',
    providedByDate: '',
    inquiredBySignature: '',
    inquiredDesignation: '',
    providedBySignature: '',
    responsibilityCenter: {},
    providedByDesignation: '',
    items: [
      {
        itemNo: '',
        quantity: '',
        id: uuidv4(),
        unitOfIssue: '',
        statusOfStock: '',
        itemDescription: '',
      },
    ],
  });

  const [lguOptions, setLguOptions] = useState([]);
  const [itemOptions, setitemsOptions] = useState([]);
  const [showRemarks, setShowRemarks] = useState(false);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [responsibilityCenterOptions, setResponsibilityCenterOptions] = useState([]);

  const separateDateAndTime = (dateTimeString) => {
    if (!dateTimeString) return;

    const dateObject = new Date(dateTimeString);

    const month = dateObject.toLocaleString('default', { month: 'short' });
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

    return { date: dateString, time: timeString };
  };

  const handleClose = () => {
    setShowSIA(false);

    setData({
      lgu: {},
      code: '',
      number: '',
      Officer: {},
      noDate: '',
      division: {},
      responsibilityCenter: {},
      purpose: '',
      inquiredByID: '',
      inquiredBySignature: '',
      inquiredByName: '',
      inquiredDesignation: '',
      providedByID: '',
      providedBySignature: '',
      providedByName: '',
      providedByDesignation: '',
      providedByDate: '',
      items: [
        {
          itemNo: '',
          quantity: '',
          id: uuidv4(),
          unitOfIssue: '',
          statusOfStock: '',
          itemDescription: '',
        },
      ],
    });
  };

  const addRow = () => {
    const newRow = {
      id: uuidv4(),
      itemNo: '',
      quantity: '',
      unitOfIssue: '',
      statusOfStock: '',
      itemDescription: '',
    };

    const _items = data?.items;
    const _newItems = _items.concat([newRow]);

    setData((prev) => ({ ...prev, items: _newItems }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    const saveData = {
      code: data?.code,
      no_date: data?.noDate,
      no: data?.number,
      lgu_id: data?.lgu?.id || '',
      purpose_remarks: data?.purpose,
      responsibility_center_id: data?.responsibilityCenter?.id || '',
      office_id: data?.office?.id || '',
      division_id: data?.division?.id || '',
      items: data?.items?.map((dd) => {
        return {
          unit: dd?.unitOfIssue,
          quantity: dd?.quantity,
          inventory_id: dd?.itemNo?.id,
          status_of_stocks: dd?.statusOfStock,
        };
      }),
      provided_by_id: data?.providedByID,
      inquired_by_id: data?.inquiredByID,
    };

    const filteredData = objectCleaner(saveData);

    let emptyCheck = false;
    emptyCheck = checkEmpty(filteredData, ['items']);

    let arrCheck = false;
    filteredData?.items?.forEach((dd) => {
      const result = checkEmpty(dd);

      if (result) {
        arrCheck = result;
      }
    });

    if (emptyCheck || arrCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    const formData = new URLSearchParams();
    formData.append('code', saveData?.code);
    formData.append('no_date', saveData?.no_date);
    formData.append('no', saveData?.no);
    formData.append('purpose_remarks', saveData?.purpose_remarks);
    formData.append('provided_by_id', saveData?.provided_by_id);
    formData.append('inquired_by_id', saveData?.inquired_by_id);

    console.log('filteredData', filteredData);

    if (filteredData?.lgu_id) {
      formData.append('lgu_id', saveData?.lgu_id);
    }

    if (filteredData?.responsibility_center_id) {
      formData.append('responsibility_center_id', saveData?.responsibility_center_id);
    }

    if (filteredData?.office_id) {
      formData.append('office_id', saveData?.office_id);
    }

    if (filteredData?.division_id) {
      formData.append('division_id', saveData?.division_id);
    }

    saveData?.items?.forEach((element, idx) => {
      formData.append(`items[${idx}]`, JSON.stringify(element));
    });

    try {
      const response = await dispatch(updateSAI({ params: formData, id: data?.id }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handleRefreshData();
        toast.info('Successfully Created SAI Form');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUserSelectInquiredBy = (e) => {
    setData((prev) => {
      return {
        ...prev,
        inquiredByName: e,
        inquiredByID: e?.id,
        inquiredDesignation: e?.attributes?.role,
        inquiredBySignature: e?.attributes?.signature,
      };
    });
  };

  const handleUserSelectProvidedBy = (e) => {
    setData((prev) => {
      return {
        ...prev,
        providedByName: e,
        providedByID: e?.id,
        providedByDesignation: e?.attributes?.role,
        providedBySignature: e?.attributes?.signature,
      };
    });
  };

  const handleSelectData = (e) => (name) => {
    setData((prev) => {
      return {
        ...prev,
        [name]: e,
      };
    });
  };

  const handleTableChange = (e) => (tableData) => {
    const { name, value } = e.target;

    const newItemData = data?.items?.map((dd) => {
      if (dd?.id === tableData?.id) {
        return {
          ...dd,
          [name]: value,
        };
      }

      return dd;
    });

    setData((prev) => ({ ...prev, items: newItemData }));
  };

  const handleApprove = async () => {
    try {
      const response = await dispatch(approveSAIForm(data?.id));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handleRefreshData();
        toast.info('Successfully Approved Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleRelease = async () => {
    try {
      const response = await dispatch(releaseItems(data?.id));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handleRefreshData();
        toast.info('Successfully Released Item');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSubmitRemarks = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('reject_remarks', data?.remarks);

      const response = await dispatch(denySAIForm({ params: formData, id: data?.id }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        closeRemarks();
        handleRefreshData();
        toast.info('Successfully Declined Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const closeRemarks = () => {
    setShowRemarks(false);
  };

  const handleTableSelectedItem = (e, dataName, tableData) => {
    const newItemData = data?.items?.map((dd) => {
      if (dd?.id === tableData?.id) {
        return {
          ...dd,
          [dataName]: e,
          itemDescription: e?.attributes?.item_name,
        };
      }

      return dd;
    });

    setData((prev) => ({ ...prev, items: newItemData }));
  };

  const handleReject = () => {
    setShowRemarks(true);
  };

  useEffect(() => {
    if (show && tableData) {
      try {
        axios
          .get('api/users', {
            params: {
              per_page: 1000,
              // ['roles[0]']: 'Account Officer',
            },
          })
          .then((response) => {
            console.log('TABLE DATA', tableData);

            // const requestByID =
            //   tableData?.purchaseRequestData[0]?.attributes?.requested_by_id || null;
            // const filteredRequestedByData = response?.data?.data?.find(
            //   (dd) => +dd?.id === +requestByID,
            // );

            const items = tableData?.items?.map((dd) => ({
              ...dd,
              id: uuidv4(),
              unitOfIssue: dd?.attributes?.unit,
              quantity: dd?.attributes?.quantity,
              statusOfStock: dd?.attributes?.status_of_stocks,
              itemDescription: dd?.attributes?.item_description,
              itemNo: { label: dd?.attributes?.item_no, value: dd?.attributes?.item_no },
            }));

            const totalCost = tableData?.items?.reduce((dd, e) => {
              return +dd + +e?.attributes?.estimated_cost;
            }, 0);
            const { date, time } = separateDateAndTime(tableData?.attributes?.created_at);

            setData((prev) => ({
              ...prev,
              id: tableData?.id,
              lgu: { value: tableData?.attributes?.lgu, label: tableData?.attributes?.lgu },
              code: tableData?.attributes?.code,
              number: tableData?.attributes?.no,
              office: {
                value: tableData?.attributes?.office,
                label: tableData?.attributes?.office,
              },
              noDate: tableData?.attributes?.no_date,
              division: {
                value: tableData?.attributes?.division,
                label: tableData?.attributes?.division,
              },
              responsibilityCenter: {
                value: tableData?.attributes?.responsibility_center,
                label: tableData?.attributes?.responsibility_center,
              },
              purpose: tableData?.attributes?.purpose_remarks,
              inquiredByID: tableData?.attributes?.inquired_by_id,
              inquiredBySignature: tableData?.attributes?.inquired_by_esignature,
              inquiredByName: {
                value: tableData?.attributes?.inquired_by,
                label: tableData?.attributes?.inquired_by,
              },
              inquiredDesignation: tableData?.attributes?.inquired_by_designation,
              providedByID: tableData?.attributes?.provided_by_id,
              providedBySignature: tableData?.attributes?.provided_by_esignature,
              providedByName: {
                value: tableData?.attributes?.provided_by,
                label: tableData?.attributes?.provided_by,
              },
              providedByDesignation: tableData?.attributes?.provided_by_designation,
              providedByDate: tableData?.attributes?.provided_by_date,
              items: items,
              status: tableData?.attributes?.status,
            }));
          });
      } catch (error) {
        console.log('Error', error);
      }
    }
  }, [show, tableData]);

  useEffect(() => {
    if (show) {
      const getDivisions = async () => {
        try {
          axios.get('api/division').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setDivisionOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getOffices = async () => {
        try {
          axios.get('api/office').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setOfficeOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getLGUs = async () => {
        try {
          axios.get('api/lgu').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setLguOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getCenters = async () => {
        try {
          axios.get('api/responsibility').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setResponsibilityCenterOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getItems = async () => {
        try {
          axios
            .get('api/inventory', {
              params: { per_page: '1000' },
            })
            .then((response) => {
              const newData = response?.data?.data.map((dd) => {
                return {
                  ...dd,
                  value: dd?.id,
                  label: dd?.id,
                };
              });

              setitemsOptions(newData);
            });
        } catch (error) {
          console.log('Error', error);
        }
      };

      getLGUs();
      getItems();
      getCenters();
      getOffices();
      getDivisions();
    }
  }, [show]);

  const canApproveSAI = checkStringPermission('can approve SAI');
  const canReleaseItems = checkStringPermission('can release items from sai');

  console.log('showRemarks', showRemarks);

  return (
    <div className={`${styles?.addItem} ${show ? styles?.active : ''}`}>
      <div className={styles?.padding}>
        <div className={styles?.header}>
          <p>SUPPLY AVAILABILITY INQUIRY</p>

          <CloseSVG className={styles?.close} onClick={handleClose} />
        </div>

        <div className={styles?.body}>
          <hr className={styles?.hr} />

          <Form.Group className={styles?.centerGroup}>
            <Select
              value={data?.lgu}
              options={lguOptions}
              styles={stylesOptions}
              placeholder='Enter Here'
              onChange={(e) => handleSelectData(e)('lgu')}
            />

            <Form.Label>LGU</Form.Label>
          </Form.Group>

          <hr className={styles?.hr} />

          <div className={styles?.formHeader}>
            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Division:</Form.Label>

                <div className={styles?.selectContainer}>
                  <Select
                    styles={stylesOptions}
                    value={data?.division}
                    placeholder='Enter Here'
                    options={divisionOptions}
                    // isDisabled={canEdit === false}
                    onChange={(e) => handleSelectData(e)('division')}
                  />
                </div>
              </Form.Group>

              <Form.Group className={`${styles?.group} ${styles?.centered}`}>
                <Form.Label>Office</Form.Label>

                <div className={styles?.selectContainer}>
                  <Select
                    value={data?.office}
                    styles={stylesOptions}
                    options={officeOptions}
                    placeholder='Enter Here'
                    // isDisabled={canEdit === false}
                    onChange={(e) => handleSelectData(e)('office')}
                  />
                </div>
              </Form.Group>
            </div>

            <div className={styles?.line}></div>

            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Responsibility Center:</Form.Label>

                <div className={styles?.selectContainer}>
                  <Select
                    styles={stylesOptions}
                    value={data?.responsibilityCenter}
                    placeholder='Enter Here'
                    options={responsibilityCenterOptions}
                    // isDisabled={canEdit === false}
                    onChange={(e) => handleSelectData(e)('responsibilityCenter')}
                  />
                </div>
              </Form.Group>

              <Form.Group className={`${styles?.group} ${styles?.centered}`}>
                <Form.Label>Code:</Form.Label>

                <FormControl
                  name='code'
                  value={data?.code}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>

            <div className={styles?.line}></div>

            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>No:</Form.Label>

                <FormControl
                  name='number'
                  value={data?.number}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>

              <Form.Group className={`${styles?.group} ${styles?.centered}`}>
                <Form.Label>Date:</Form.Label>

                <FormControl
                  type='date'
                  name='noDate'
                  value={data?.noDate}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
          </div>

          <div className={styles?.form}>
            <Table>
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Item Description</th>
                  <th>Unit</th>
                  <th>Quantity</th>
                  <th>Status of Stock</th>
                </tr>
              </thead>
              <tbody>
                {data?.items &&
                  data?.items?.map((dd) => {
                    return (
                      <tr key={dd?.id}>
                        <td>
                          <Select
                            value={dd?.itemNo}
                            options={itemOptions}
                            styles={stylesOptions}
                            placeholder='Enter Here'
                            className={styles?.itemSelect}
                            onChange={(e) => handleTableSelectedItem(e, 'itemNo', dd)}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemDescription'
                            placeholder='Enter Here'
                            value={dd?.itemDescription}
                            onChange={(e) => handleTableChange(e)(dd)}
                          />
                        </td>
                        <td>
                          <Form.Control
                            name='unitOfIssue'
                            value={dd?.unitOfIssue}
                            placeholder='Enter Here'
                            onChange={(e) => handleTableChange(e)(dd)}
                          />
                        </td>
                        <td>
                          <Form.Control
                            name='quantity'
                            type='number'
                            value={dd?.quantity}
                            placeholder='Enter Here'
                            onChange={(e) => handleTableChange(e)(dd)}
                          />
                        </td>

                        <td>
                          <Form.Select
                            name='statusOfStock'
                            value={dd?.statusOfStock}
                            onChange={(e) => handleTableChange(e)(dd)}
                          >
                            <option value='' hidden>
                              - Select -
                            </option>
                            <option value='available'>Available</option>
                            <option value='unavailable'>Unavailable</option>
                          </Form.Select>
                        </td>
                      </tr>
                    );
                  })}

                <tr>
                  <td colSpan={7} className={styles?.addRow}>
                    <Button onClick={addRow} className={styles?.addRow}>
                      <CirclePlus />
                      Add Row
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className={styles?.purpose}>
            <Form.Group className={styles?.group}>
              <Form.Label>Purpose / Remarks:</Form.Label>
              <Form.Control
                name='purpose'
                value={data?.purpose}
                onChange={handleChange}
                placeholder='Enter Here'
              />
            </Form.Group>
          </div>

          <hr className={styles?.hr} />

          <div className={styles?.signatures}>
            <div className={styles?.left}>
              <p>INQUIRED BY:</p>

              <Form.Group className={styles?.group}>
                <Form.Label>Signature:</Form.Label>

                <div className={styles?.signatureContainer}>
                  {data?.inquiredBySignature && data?.inquiredBySignature !== null && (
                    <Image className={styles?.signatureImage} src={data?.inquiredBySignature} />
                  )}

                  <Form.Control readOnly />
                </div>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Name:</Form.Label>

                <div className={styles?.inquiredByName}>
                  <SelectUser
                    show={show}
                    data={data?.inquiredByName}
                    stylesOptions={stylesOptions}
                    onChange={handleUserSelectInquiredBy}
                    params={{
                      per_page: 1000,
                    }}
                  />
                </div>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Designation:</Form.Label>

                <Form.Control
                  disabled
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='inquiredDesignation'
                  value={data?.inquiredDesignation}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Date:</Form.Label>

                <Form.Control
                  disabled
                  type='date'
                  name='inquiredByDate'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.inquiredByDate}
                />
              </Form.Group>
            </div>

            <div className={styles?.line}></div>

            <div className={styles?.right}>
              <p>STATUS PROVIDED BY:</p>

              <Form.Group className={styles?.group}>
                <Form.Label>Signature:</Form.Label>

                <div className={styles?.signatureContainer}>
                  {data?.providedBySignature && data?.providedBySignature !== null && (
                    <Image className={styles?.signatureImage} src={data?.providedBySignature} />
                  )}

                  <Form.Control readOnly />
                </div>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Name:</Form.Label>

                <div className={styles?.inquiredByName}>
                  <SelectUser
                    show={show}
                    data={data?.providedByName}
                    stylesOptions={stylesOptions}
                    onChange={handleUserSelectProvidedBy}
                    params={{
                      per_page: 1000,
                      ['roles[]']: 'Requisition Officer',
                    }}
                  />
                </div>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Designation:</Form.Label>

                <Form.Control
                  disabled
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='providedByDesignation'
                  value={data?.providedByDesignation}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Date:</Form.Label>

                <Form.Control
                  disabled
                  type='date'
                  name='providedByDate'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.providedByDate}
                />
              </Form.Group>
            </div>
          </div>

          {data?.status === 'approved' && (
            <div className={styles?.approved}>
              <p>APPROVED</p>
            </div>
          )}

          {data?.status === 'rejected' && (
            <>
              <Form.Group className={styles?.remarks}>
                <Form.Label>Reject Remarks</Form.Label>
                <Form.Control
                  rows={5}
                  as='textarea'
                  value={data?.rejectRemarks}
                  readOnly={data?.status !== 'for approval'}
                />
              </Form.Group>

              <div className={styles?.rejected}>
                <p>REJECTED</p>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles?.buttons}>
        {canReleaseItems && data?.status === 'for releasing' && (
          <Button
            onClick={handleRelease}
            className={styles?.save}
            disabled={releaseLogs?.isLoading}
          >
            Release
          </Button>
        )}

        {canApproveSAI && (
          <>
            <Button
              onClick={handleApprove}
              className={styles?.save}
              disabled={approveLogs?.isLoading}
            >
              Approve Request
            </Button>
            <Button
              onClick={handleReject}
              className={styles?.reject}
              disabled={declinedLogs?.isLoading}
            >
              Reject Request
            </Button>
          </>
        )}

        {data?.status === 'for approval' &&
          canApproveSAI === false &&
          canReleaseItems === false && (
            <Button
              onClick={handleUpdate}
              className={styles?.save}
              disabled={updateLogs?.isLoading}
            >
              Update SAI
            </Button>
          )}

        {canApproveSAI === false && (
          <Button onClick={handleClose} className={styles?.cancel}>
            Close
          </Button>
        )}
      </div>

      <Remarks
        data={data}
        showRemarks={showRemarks}
        handleClose={closeRemarks}
        handleChange={handleChange}
        isLoading={declinedLogs?.isLoading}
        handleSubmitRemarks={handleSubmitRemarks}
      />
    </div>
  );
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    height: '38px',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '0',
    textAlign: 'center',
    fontStyle: 'italic',
    background: 'transparent',
    borderBottom: 'solid 1px #000',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '14px',
    background: '#F3F3F3',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

export default ViewSiaForm;
