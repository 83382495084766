/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getSAI,
  createSAI,
  updateSAI,
  approveSAIForm,
  denySAIForm,
  releaseItems,
} from './thunks/saiThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  data: [],
  meta: null,
  logs: initialLog,
  updateLogs: initialLog,
  approveLogs: initialLog,
  releaseLogs: initialLog,
  declinedLogs: initialLog,
  createSAILogs: initialLog,
};

const slice = createSlice({
  name: 'sai',
  initialState,
  reducers: {},
  extraReducers: {
    [createSAI.fulfilled]: (state, action) => {
      state.createSAILogs.isSuccess = true;
      state.createSAILogs.isLoading = false;
    },
    [createSAI.pending]: (state) => {
      state.createSAILogs.error = '';
      state.createSAILogs.isFailed = false;
      state.createSAILogs.isLoading = true;
      state.createSAILogs.isSuccess = false;
    },
    [createSAI.rejected]: (state, action) => {
      state.createSAILogs.isFailed = true;
      state.createSAILogs.isLoading = false;
      if (action?.payload?.errors) {
        state.createSAILogs.error = action?.payload?.errors[0]?.detail;
      } else {
        state.createSAILogs.error = action?.payload?.message?.detail;
      }
    },
    [getSAI.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      console.log('action', action);
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getSAI.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getSAI.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [updateSAI.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [updateSAI.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [updateSAI.rejected]: (state, action) => {
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
      state.updateLogs.error = action?.payload?.errors[0]?.detail;
    },
    [approveSAIForm.fulfilled]: (state, action) => {
      state.approveLogs.isSuccess = true;
      state.approveLogs.isLoading = false;
    },
    [approveSAIForm.pending]: (state) => {
      state.approveLogs.error = '';
      state.approveLogs.isFailed = false;
      state.approveLogs.isLoading = true;
      state.approveLogs.isSuccess = false;
    },
    [approveSAIForm.rejected]: (state, action) => {
      state.approveLogs.isFailed = true;
      state.approveLogs.isLoading = false;
      state.approveLogs.error = action?.payload?.errors[0]?.detail;
    },
    [denySAIForm.fulfilled]: (state, action) => {
      state.declinedLogs.isSuccess = true;
      state.declinedLogs.isLoading = false;
    },
    [denySAIForm.pending]: (state) => {
      state.declinedLogs.error = '';
      state.declinedLogs.isFailed = false;
      state.declinedLogs.isLoading = true;
      state.declinedLogs.isSuccess = false;
    },
    [denySAIForm.rejected]: (state, action) => {
      state.declinedLogs.isFailed = true;
      state.declinedLogs.isLoading = false;
      state.declinedLogs.error = action?.payload?.errors[0]?.detail;
    },
    [releaseItems.fulfilled]: (state, action) => {
      state.releaseLogs.isSuccess = true;
      state.releaseLogs.isLoading = false;
    },
    [releaseItems.pending]: (state) => {
      state.releaseLogs.error = '';
      state.releaseLogs.isFailed = false;
      state.releaseLogs.isLoading = true;
      state.releaseLogs.isSuccess = false;
    },
    [releaseItems.rejected]: (state, action) => {
      state.releaseLogs.isFailed = true;
      state.releaseLogs.isLoading = false;
      state.releaseLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: saiActions, reducer: saiReducer } = slice;

export const { updateUserinfo } = saiActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.sai || initialState;

export const selectSai = createSelector([selectDomain], (saiState) => saiState);

/* --------------------------------------------------------------------------------- */

export { getSAI, createSAI, updateSAI, approveSAIForm, denySAIForm, releaseItems };
