/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkStringPermission } from 'helpers/filteredPermissions';

const ProtectedRoute = ({ page, component: Component }) => {
  const conditions = [
    {
      key: 'Inventory',
      permission: 'can view inventory module',
    },
    {
      key: 'Item Request',
      permission: 'can view item request module',
    },
    {
      key: 'Transaction',
      permission: 'can view transactions module',
    },
    {
      key: 'Approver',
      permission: 'can view approver module',
    },
    {
      key: 'Reports',
      permission: 'can view reports module',
    },
    {
      key: 'Inventory Staff',
      permission: 'can view inventory staff module',
    },
    {
      key: 'Audit Trail',
      permission: 'can view audit module',
    },
    {
      key: 'Users',
      permission: 'can view user module',
    },
    {
      key: 'ACL',
      permission: 'can view acl module',
    },
    {
      key: 'Purchase Request',
      permission: 'can view purchase request module',
    },
    {
      key: 'Purchase Order',
      permission: 'can view purchase order module',
    },
    {
      key: 'Purchase Request Reports',
      permission: 'can view purchase request reports module',
    },
    {
      key: 'Purchase Order Reports',
      permission: 'can view purchase orders reports module',
    },
    {
      key: 'SAI',
      permission: 'can view sai module',
    },
    {
      key: 'SAI Reports',
      permission: 'can view sai reports module',
    },
    {
      key: 'AIR Reports',
      permission: 'can view air reports module',
    },
  ];

  const pages = conditions.filter((data) => data.key === page);

  if (pages.length > 0) {
    const result = checkStringPermission(pages[0]?.permission);

    if (result) {
      return <Component />;
    }

    return <Navigate to='/' />;
  }
};

export default ProtectedRoute;
