/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPurchaseRequest = createAsyncThunk(
  'getPurchaseRequest',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `/api/purchase-request`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const requestForm = createAsyncThunk('requestForm', async (params, { rejectWithValue }) => {
  return await axios({
    data: params,
    method: 'post',
    url: `/api/purchase-request?include[]=item`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const updateRequestForm = createAsyncThunk(
  'updateRequestForm',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'put',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      url: `/api/purchase-request/${id}?include[]=item`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const approveRequestForm = createAsyncThunk(
  'approveRequestForm',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `/api/purchase-request/approve/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const denyRequestForm = createAsyncThunk(
  'denyRequestForm',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      data: params,
      url: `/api/purchase-request/reject/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
