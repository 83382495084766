/* eslint-disable multiline-comment-style */
/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FormControl, Table, Image } from 'react-bootstrap';

import Remarks from '../Remarks';
import { checkEmpty } from 'utils/validator';
import SelectUser from 'components/Layouts/SelectUser';
import { createAirForm, selectAIR, approveAIR } from 'reducers/airFormReducer';
import { denyOrderForm, approveOrderForm } from 'reducers/purchaseOrderReducer';

import styles from './ViewAIRForm.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';
import { ReactComponent as PrintSVG } from 'assets/svg/prime_print.svg';
import { ReactComponent as ExportSVG } from 'assets/svg/icon-park_export.svg';

const ViewAIRForm = ({ show = false, setShowAIR, tableData, handlePurchaseOrder }) => {
  const dispatch = useDispatch();

  const stateAIRFormState = useSelector(selectAIR);
  const { createAirFormLogs, approveAirFormLogs } = stateAIRFormState;

  const [data, setData] = useState({
    id: '',
    lgu: '',
    pONo: '',
    airNo: '',
    supplier: '',
    pONoDate: '',
    invoiceNo: '',
    tableData: {},
    acceptedBy: '',
    inspectedBy: '',
    acceptedDate: '',
    acceptedByID: '',
    receivedDate: '',
    invoiceNoDate: '',
    inspectedDate: '',
    isVerified: false,
    inspectedByID: '',
    acceptedSelection: '',
    acceptedBySignature: null,
    inspectedBySignature: null,

    items: [
      {
        id: uuidv4(),
        itemNo: '',
        quantity: '',
        unitOfIssue: '',
        itemDescription: '',
        estimatedUnitCost: '',
        estimatedCost: '',
      },
    ],
  });

  const [showRemarks, setShowRemarks] = useState(false);
  const [supplierOptions, setSupplierOptions] = useState([]);

  const handleClose = () => {
    setShowAIR(false);

    setData({
      lgu: '',
      prNo: '',
      saiNo: '',
      address: '',
      alobsNo: '',
      prNoDate: '',
      supplier: '',
      saiNoDate: '',
      alobsNoDate: '',
      items: [
        {
          id: uuidv4(),
          itemNo: '',
          quantity: '',
          unitOfIssue: '',
          itemDescription: '',
          estimatedUnitCost: '',
          estimatedCost: '',
        },
      ],
      purpose: '',
      requestedBy: '',
      requestedDesignation: '',
      approvedBy: '',
      approvedDesignation: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckContent = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const changeCheckbox = (e) => {
    const { name, checked } = e.target;

    setData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSave = async () => {
    const saveData = {
      invoice_no: data?.invoiceNo,
      purchase_order_id: data?.pONo,
      invoice_date: data?.invoiceNoDate,
      inspection_date: data?.inspectedDate,
      inspection_selection: data?.isVerified,
      requisitioning_office: data?.department,
      inspection_officer_id: data?.inspectedByID,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData, ['items']);

    if (emptyCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    // if (saveData?.isVerified === false) {
    //   toast.error('Please check the inspected and verified button');
    //   return;
    // }

    const formData = new FormData();
    formData.append('invoice_no', saveData?.invoice_no);
    formData.append('invoice_date', saveData?.invoice_date);
    formData.append('inspection_date', saveData?.inspection_date);
    formData.append('purchase_order_id', saveData?.purchase_order_id);
    formData.append('inspection_officer_id', saveData?.inspection_officer_id);
    formData.append('requisitioning_office', saveData?.requisitioning_office);
    formData.append('inspection_selection', saveData?.inspection_selection ? 1 : 0);

    try {
      const response = await dispatch(createAirForm(formData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseOrder();
        toast.info('Successfully Created AIR Form');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdate = async () => {
    const saveData = {
      acceptance_selection: data?.acceptance,
      acceptance_date: data?.receivedDate,
      acceptance_by: data?.propertyOfficerID,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData);

    if (emptyCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    const formData = new URLSearchParams();
    formData.append('acceptance_date', saveData?.acceptance_date);
    formData.append('property_officer_id', saveData?.acceptance_by);
    formData.append('acceptance_selection', saveData?.acceptance_selection);

    console.log('saveData', saveData);

    try {
      const response = await dispatch(approveAIR({ params: formData, id: data?.airNo }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseOrder();
        toast.info('Successfully Signed AIR Form');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleRequisitionOfficer = (e) => {
    setData((prev) => {
      return {
        ...prev,
        inspectedBy: e,
        inspectedByID: e?.id,
        inspectedBySignature: e?.attributes?.signature,
      };
    });
  };

  const handleSupplyOfficer = (e) => {
    setData((prev) => {
      return {
        ...prev,
        propertyOfficer: e,
        propertyOfficerID: e?.id,
        propertyOfficerSignature: e?.attributes?.signature,
      };
    });
  };

  const handleSelectedSupplier = (e) => (name) => {
    setData((prev) => {
      return {
        ...prev,
        [name]: e,
        address: e?.attributes?.supplier_address,
      };
    });
  };

  const separateDateAndTime = (dateTimeString) => {
    if (!dateTimeString) return;

    const dateObject = new Date(dateTimeString);

    const month = dateObject.toLocaleString('default', { month: 'short' });
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

    return { date: dateString, time: timeString };
  };

  const handleApprove = async () => {
    try {
      const response = await dispatch(approveOrderForm(data?.id));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseOrder();
        toast.info('Successfully Approved Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSubmitRemarks = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('reject_remarks', data?.remarks);

      const response = await dispatch(denyOrderForm({ params: formData, id: data?.id }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        closeRemarks();
        handlePurchaseOrder();
        toast.info('Successfully Declined Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const openRemarks = () => {
    setShowRemarks(true);
  };

  const closeRemarks = () => {
    setShowRemarks(false);
  };

  useEffect(() => {
    if (show && tableData) {
      console.log('TABLE DATA', tableData);

      const getSuppliers = async () => {
        try {
          axios.get('api/supplier').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.supplier_name,
                label: dd?.attributes?.supplier_name,
              };
            });

            setSupplierOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      getSuppliers();

      try {
        axios
          .get('api/users', {
            params: {
              per_page: 1000,
              ['roles[0]']: 'Account Officer',
              ['roles[1]']: 'Supply Officer',
              ['roles[2]']: 'Executive Officer',
            },
          })
          .then((response) => {
            console.log('tableData', tableData);
            // const requestByID =
            //   tableData?.purchaseRequestData[0]?.attributes?.requested_by_id || null;

            // const filteredRequestedByData = response?.data?.data?.find(
            //   (dd) => +dd?.id === +requestByID,
            // );

            const items = tableData?.items?.map((dd) => ({
              ...dd,
              itemNo: dd?.attributes?.item_no,
              quantity: dd?.attributes?.quantity,
              unitOfIssue: dd?.attributes?.unit_of_issue,
              estimatedCost: dd?.attributes?.estimated_cost,
              itemDescription: dd?.attributes?.item_description,
              estimatedUnitCost: dd?.attributes?.estimated_unit_cost,
            }));

            // const totalCost = tableData?.items?.reduce((dd, e) => {
            //   return +dd + +e?.attributes?.estimated_cost;
            // }, 0);

            // const { date, time } = separateDateAndTime(tableData?.attributes?.created_at);

            // const haveData = tableData?.airData?.length > 0;

            // const isOne = (input) => input === 1;

            // console.log('tableData', tableData);

            setData((prev) => ({
              ...prev,
              items: items,
              tableData: tableData,
              lgu: tableData?.attributes?.lgu,
              pONo: tableData?.attributes?.po_no,
              airNo: tableData?.attributes?.air_no,
              supplier: tableData?.attributes?.supplier,
              pONoDate: tableData?.attributes?.po_no_date,
              invoiceNo: tableData?.attributes?.invoice_no,
              invoiceNoDate: tableData?.attributes?.invoice_date,
              department: tableData?.attributes?.requisitioning_office,
              receivedDate: tableData?.attributes?.acceptance_date,
              acceptance: tableData?.attributes?.acceptance_selection,
              propertyOfficerSignature: tableData?.attributes?.property_officer_esignature,
              propertyOfficer: {
                label: tableData?.attributes?.property_officer,
                value: tableData?.attributes?.property_officer,
              },
              inspectedDate: tableData?.attributes?.inspection_date,
              // eslint-disable-next-line no-unneeded-ternary
              isVerified: tableData?.attributes?.inspection_selection === 1 ? true : false,
              inspectedBySignature: tableData?.attributes?.inspection_officer_esignature,
              inspectedByName: {
                label: tableData?.attributes?.inspection_officer,
                value: tableData?.attributes?.inspection_officer,
              },
              status: tableData?.attributes?.status,
            }));
          });
      } catch (error) {
        console.log('Error', error);
      }
    }
  }, [show, tableData]);

  const isEdit =
    data?.tableData?.airData?.length > 0 &&
    data?.tableData?.airData[0]?.attributes?.property_officer === '';

  console.log('DATAAA', data);

  return (
    <div className={`${styles?.addItem} ${show ? styles?.active : ''}`}>
      <div className={styles?.padding}>
        <div className={styles?.header}>
          <p>ACCEPTANCE & INSPECTION REPORT</p>

          <CloseSVG className={styles?.close} onClick={handleClose} />
        </div>

        <div className={styles?.body}>
          <hr className={styles?.hr} />

          <Form.Group className={styles?.centerGroup}>
            <FormControl readOnly name='lgu' value={data?.lgu} placeholder='Enter Here' />
            <Form.Label>LGU</Form.Label>
          </Form.Group>

          <div className={styles?.formHeader}>
            <div className={styles?.horizontalLine}></div>
            <Form.Group className={styles?.group}>
              <Form.Label>Supplier:</Form.Label>
              <FormControl
                readOnly
                name='supplier'
                value={data?.supplier}
                onChange={handleChange}
                placeholder='Enter Here'
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>AIR No.</Form.Label>
              <FormControl
                readOnly
                name='airNo'
                value={data?.airNo}
                onChange={handleChange}
                placeholder='Enter Here'
              />
            </Form.Group>
            <Form.Group className={styles?.group}>
              <Form.Label>P.O. No.:</Form.Label>
              <FormControl
                readOnly
                name='pONo'
                value={data?.pONo}
                onChange={handleChange}
                placeholder='Enter Here'
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>Date:</Form.Label>
              <FormControl
                readOnly
                name='pONoDate'
                value={data?.pONoDate}
                onChange={handleChange}
                placeholder='Enter Here'
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>Invoice No.:</Form.Label>
              <FormControl
                readOnly
                name='invoiceNo'
                onChange={handleChange}
                value={data?.invoiceNo}
                placeholder='Enter Here'
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>Date:</Form.Label>
              <FormControl
                readOnly
                type='date'
                name='invoiceNoDate'
                onChange={handleChange}
                placeholder='Enter Here'
                value={data?.invoiceNoDate}
              />
            </Form.Group>

            <Form.Group className={styles?.group}>
              <Form.Label>Requisitioning Office / Department :</Form.Label>
              <FormControl
                readOnly
                name='department'
                onChange={handleChange}
                value={data?.department}
                placeholder='Enter Here'
              />
            </Form.Group>
          </div>

          <div className={styles?.form}>
            <Table>
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Unit</th>
                  <th>Item Description</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data?.items &&
                  data?.items?.map((dd) => {
                    return (
                      <tr key={dd?.id}>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemNo'
                            value={dd?.itemNo}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='unitOfIssue'
                            value={dd?.unitOfIssue}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemDescription'
                            placeholder='Enter Here'
                            value={dd?.itemDescription}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='quantity'
                            value={dd?.quantity}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='estimatedUnitCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedUnitCost}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          <div className={styles?.header}>
            <div>ACCEPTANCE</div>
            <div>INSPECTION</div>
          </div>
          <div className={styles?.acceptanceInspection}>
            <div className={styles?.verticalLine}></div>

            <div className={styles?.acceptance}>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Form.Label>Date Received:</Form.Label>
                <FormControl
                  readOnly
                  type='date'
                  name='receivedDate'
                  onChange={handleChange}
                  value={data?.receivedDate}
                />
              </div>

              <Form.Check
                disabled
                value='Complete'
                label='Complete'
                name='acceptance'
                onChange={handleCheckContent}
                checked={data?.acceptance === 'Complete'}
              />
              <Form.Check
                disabled
                value='Partial'
                label='Partial'
                name='acceptance'
                onChange={handleCheckContent}
                checked={data?.acceptance === 'Partial'}
              />

              <Form.Group>
                <div className={styles?.input}>
                  {data?.propertyOfficerSignature && data?.propertyOfficerSignature !== null && (
                    <Image
                      className={styles?.signatureImage}
                      src={data?.propertyOfficerSignature}
                    />
                  )}

                  <div className={styles?.requestedBy}>
                    <SelectUser
                      isDisabled
                      show={show}
                      data={data?.propertyOfficer}
                      stylesOptions={stylesOptions}
                      onChange={handleSupplyOfficer}
                      params={{
                        per_page: 1000,
                        ['roles[]']: 'Supply Officer',
                      }}
                    />
                  </div>
                </div>

                <Form.Label style={{ textAlign: 'center' }}>Property Officer</Form.Label>
              </Form.Group>
            </div>

            <div className={styles?.inspection}>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Form.Label>Date Inspected:</Form.Label>
                <FormControl
                  readOnly
                  type='date'
                  name='inspectedDate'
                  onChange={handleChange}
                  value={data?.inspectedDate}
                />
              </div>

              <Form.Check
                disabled
                name='isVerified'
                onChange={changeCheckbox}
                checked={data?.isVerified === true}
                label='Inspected, verified and found OK as to quantity and specifications'
              />

              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <div className={styles?.input}>
                  {data?.inspectedBySignature && data?.inspectedBySignature !== null && (
                    <Image className={styles?.signatureImage} src={data?.inspectedBySignature} />
                  )}

                  <div className={styles?.requestedBy}>
                    <SelectUser
                      isDisabled
                      show={show}
                      stylesOptions={stylesOptions}
                      data={data?.inspectedByName}
                      onChange={handleRequisitionOfficer}
                      params={{
                        per_page: 1000,
                        ['roles[]']: 'Requisition Officer',
                      }}
                    />
                  </div>
                </div>

                <Form.Label>Inspection Officer / Committee</Form.Label>
              </Form.Group>
            </div>
          </div>

          {data?.status === 'for approval' && (
            <div className={styles?.forApproval}>
              <p>FOR APPROVAL</p>
            </div>
          )}

          {data?.status === 'for releasing' && (
            <div className={styles?.forReleasing}>
              <p>FOR RELEASING</p>
            </div>
          )}

          {data?.status === 'released' && (
            <div className={styles?.approved}>
              <p>RELEASED</p>
            </div>
          )}

          {data?.status === 'rejected' && (
            <>
              <Form.Group className={styles?.remarks}>
                <Form.Label>Reject Remarks</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={5}
                  value={data?.rejectRemarks}
                  readOnly={data?.status !== 'for approval'}
                />
              </Form.Group>

              <div className={styles?.rejected}>
                <p>REJECTED</p>
              </div>
            </>
          )}

          <div className={styles?.buttons}>
            <Button
              // onClick={handleUpdate}
              className={styles?.save}
              // disabled={updateRequestFormLogs?.isLoading}
            >
              <ExportSVG />
              Export
            </Button>
            <Button onClick={handleClose} className={styles?.cancel}>
              <PrintSVG /> Print
            </Button>
          </div>
        </div>
      </div>

      <Remarks
        data={data}
        showRemarks={showRemarks}
        handleClose={closeRemarks}
        handleChange={handleChange}
        // isLoading={declinedLogs?.isLoading}
        handleSubmitRemarks={handleSubmitRemarks}
      />
    </div>
  );
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    height: '38px',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '0',
    textAlign: 'center',
    fontStyle: 'italic',
    background: 'transparent',
    borderBottom: 'solid 1px #000',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '14px',
    background: '#F3F3F3',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

export default ViewAIRForm;
