/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FormControl, Table, Image } from 'react-bootstrap';

import Remarks from '../Remarks';
import { checkEmpty } from 'utils/validator';
import SelectUser from 'components/Layouts/SelectUser';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';
import {
  denyOrderForm,
  approveOrderForm,
  selectpurchaseOrder,
  updatePurchaseOrder,
} from 'reducers/purchaseOrderReducer';

import styles from './PurchaseOrderModal.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

const PurchaseOrderModal = ({ show = false, setShowPO, tableData, handlePurchaseOrder }) => {
  const dispatch = useDispatch();

  const statePurchaseOrder = useSelector(selectpurchaseOrder);
  const { updateLogs, approveLogs, declinedLogs } = statePurchaseOrder;

  const [data, setData] = useState({
    id: '',
    lgu: '',
    pRNo: '',
    pOno: '',
    date: '',
    datePO: '',
    status: '',
    supplier: '',
    address: '',
    paymentTerm: '',
    totalAmount: '',
    deliveryTerm: '',
    dateOfDelivery: '',
    placeOfDelivery: '',
    certifiedCorrect: '',
    purchaseRequestId: '',
    modeOfProcurement: '',
    authorizedOfficial: '',
    supplyOfficerName: '',
    supplyOfficerSignature: null,
    supplyOfficerSignatureDate: '',
    executiveOfficerSignature: null,
    executiveOfficerName: '',
    approvedSanggunianResolutionNo: '',
    items: [
      {
        id: uuidv4(),
        itemNo: '',
        quantity: '',
        unitOfIssue: '',
        itemDescription: '',
        estimatedUnitCost: '',
        estimatedCost: '',
      },
    ],
  });

  const [showRemarks, setShowRemarks] = useState(false);
  const [supplierOptions, setSupplierOptions] = useState([]);

  const handleClose = () => {
    setShowPO(false);

    setData({
      lgu: '',
      prNo: '',
      saiNo: '',
      address: '',
      alobsNo: '',
      prNoDate: '',
      supplier: '',
      saiNoDate: '',
      alobsNoDate: '',
      items: [
        {
          id: uuidv4(),
          itemNo: '',
          quantity: '',
          unitOfIssue: '',
          itemDescription: '',
          estimatedUnitCost: '',
          estimatedCost: '',
        },
      ],
      purpose: '',
      requestedBy: '',
      requestedDesignation: '',
      approvedBy: '',
      approvedDesignation: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const saveData = {
      id: data?.id,
      items: data?.items,
      address: data?.address,
      payment_term: data?.paymentTerm,
      delivery_term: data?.deliveryTerm,
      supplier_name: data?.supplier,
      date_of_delivery: data?.dateOfDelivery,
      place_of_delivery: data?.placeOfDelivery,
      purchase_request_id: data?.purchaseRequestId,
      mode_of_procurement: data?.modeOfProcurement,
      total_amount_in_cash: data?.totalAmount,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData, ['items']);

    let arrCheck = false;
    saveData?.items?.forEach((dd) => {
      const result = checkEmpty(dd);

      if (result) {
        arrCheck = result;
      }
    });

    if (emptyCheck || arrCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    const formData = new URLSearchParams();
    formData.append('address', saveData?.address);
    formData.append('payment_term', saveData?.payment_term);
    formData.append('delivery_term', saveData?.delivery_term);
    formData.append('date_of_delivery', saveData?.date_of_delivery);
    formData.append('supplier_name', saveData?.supplier_name?.value);
    formData.append('place_of_delivery', saveData?.place_of_delivery);
    formData.append('purchase_request_id', saveData?.purchase_request_id);
    formData.append('mode_of_procurement', saveData?.mode_of_procurement);
    formData.append('total_amount_in_cash', saveData?.total_amount_in_cash);

    saveData?.items?.forEach((element, idx) => {
      formData.append(`items[${idx}]`, JSON.stringify(element));
    });

    try {
      const response = await dispatch(
        updatePurchaseOrder({
          id: data?.id,
          params: formData,
        }),
      );

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseOrder();
        toast.info('Successfully Updated Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUserSelectRequestedBy = (e) => {
    setData((prev) => {
      return {
        ...prev,
        requestedBy: e,
        requestedByID: e?.id,
        requestedDesignation: e?.attributes?.role,
        requestedBySignature: e?.attributes?.signature,
      };
    });
  };

  const handleSelectedSupplier = (e) => (name) => {
    setData((prev) => {
      return {
        ...prev,
        [name]: e,
        address: e?.attributes?.supplier_address,
      };
    });
  };

  const separateDateAndTime = (dateTimeString) => {
    if (!dateTimeString) return;

    const dateObject = new Date(dateTimeString);

    const month = dateObject.toLocaleString('default', { month: 'short' });
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

    return { date: dateString, time: timeString };
  };

  const handleApprove = async () => {
    try {
      const response = await dispatch(approveOrderForm(data?.id));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseOrder();
        toast.info('Successfully Approved Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSubmitRemarks = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('reject_remarks', data?.remarks);

      const response = await dispatch(denyOrderForm({ params: formData, id: data?.id }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        closeRemarks();
        handlePurchaseOrder();
        toast.info('Successfully Declined Purchase Order');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const openRemarks = () => {
    setShowRemarks(true);
  };

  const closeRemarks = () => {
    setShowRemarks(false);
  };

  useEffect(() => {
    if (show && tableData) {
      console.log('TABLE DATA', tableData);

      const getSuppliers = async () => {
        try {
          axios.get('api/supplier').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.supplier_name,
                label: dd?.attributes?.supplier_name,
              };
            });

            setSupplierOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      getSuppliers();

      try {
        axios
          .get('api/users', {
            params: {
              per_page: 1000,
              ['roles[0]']: 'Account Officer',
              ['roles[1]']: 'Supply Officer',
              ['roles[2]']: 'Executive Officer',
            },
          })
          .then((response) => {
            const requestByID =
              tableData?.purchaseRequestData[0]?.attributes?.requested_by_id || null;

            const filteredRequestedByData = response?.data?.data?.find(
              (dd) => +dd?.id === +requestByID,
            );

            const items = tableData?.items?.map((dd) => ({
              ...dd,
              itemNo: dd?.attributes?.item_no,
              quantity: dd?.attributes?.quantity,
              unitOfIssue: dd?.attributes?.unit_of_issue,
              estimatedCost: dd?.attributes?.estimated_cost,
              itemDescription: dd?.attributes?.item_description,
              estimatedUnitCost: dd?.attributes?.estimated_unit_cost,
            }));

            const totalCost = tableData?.items?.reduce((dd, e) => {
              return +dd + +e?.attributes?.estimated_cost;
            }, 0);

            const { date, time } = separateDateAndTime(tableData?.attributes?.created_at);

            setData((prev) => ({
              ...prev,
              items: items,
              id: tableData?.id,
              totalAmount: totalCost,
              status: tableData?.status,
              lgu: tableData?.attributes?.lgu,
              purchaseRequestId: tableData?.id,
              supplyOfficerSignatureDate: date,
              pOno: tableData?.attributes?.po_no,
              pRNo: tableData?.attributes?.pr_no,
              datePO: tableData?.attributes?.po_no_date,
              paymentTerm: tableData?.attributes?.payment_term,
              address: tableData?.attributes?.supplier_address,
              deliveryTerm: tableData?.attributes?.delivery_term,
              rejectRemarks: tableData?.attributes?.reject_remarks,
              dateOfDelivery: tableData?.attributes?.date_of_delivery,
              placeOfDelivery: tableData?.attributes?.place_of_delivery,
              modeOfProcurement: tableData?.attributes?.mode_of_procurement,
              supplyOfficerSignature: filteredRequestedByData?.attributes?.signature,
              executiveOfficerSignature: tableData?.attributes?.executive_conforme_by_esignature,
              supplier: {
                label: tableData?.attributes?.supplier,
                value: tableData?.attributes?.supplier,
              },
              executiveOfficerName: {
                label: tableData?.attributes?.executive_conforme_by,
                value: tableData?.attributes?.executive_conforme_by,
              },
              supplyOfficerName: {
                label: `${filteredRequestedByData?.attributes?.first_name} ${filteredRequestedByData?.attributes?.last_name}`,
                value: `${filteredRequestedByData?.attributes?.first_name} ${filteredRequestedByData?.attributes?.last_name}`,
              },
            }));
          });
      } catch (error) {
        console.log('Error', error);
      }
    }
  }, [show, tableData]);

  const canCreatePO = checkStringPermission('can create purchase request');
  const canApprovePO = checkStringPermission('can final approve purchase order');

  const canEdit = canCreatePO === true && data?.status === 'for approval';

  return (
    <div className={`${styles?.addItem} ${show ? styles?.active : ''}`}>
      <div className={styles?.padding}>
        <div className={styles?.header}>
          <p>PURCHASE ORDER</p>

          <CloseSVG className={styles?.close} onClick={handleClose} />
        </div>

        <div className={styles?.body}>
          <hr className={styles?.hr} />

          <Form.Group className={styles?.centerGroup}>
            <FormControl readOnly name='lgu' value={data?.lgu} placeholder='Enter Here' />
            <Form.Label>LGU</Form.Label>
          </Form.Group>

          <div className={styles?.formHeader}>
            <div className={styles?.col}>
              {/* <Form.Group className={styles?.group}>
                <Form.Label>Supplier</Form.Label>
                <FormControl
                  name='supplier'
                  value={data?.supplier}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group> */}

              <Form.Group className={styles?.group}>
                <Form.Label>Supplier</Form.Label>
                <div className={styles?.selectContainer}>
                  <Select
                    styles={stylesOptions}
                    value={data?.supplier}
                    placeholder='Enter Here'
                    options={supplierOptions}
                    isDisabled={canEdit === false}
                    onChange={(e) => handleSelectedSupplier(e)('supplier')}
                  />
                </div>
              </Form.Group>

              <Form.Group className={`${styles?.group} ${styles?.centered}`}>
                <Form.Label>Address</Form.Label>
                <FormControl
                  readOnly
                  name='address'
                  value={data?.address}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>P.O. No.:</Form.Label>
                <FormControl
                  disabled
                  name='pOno'
                  value={data?.pOno}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Date:</Form.Label>
                <FormControl
                  disabled
                  name='datePO'
                  value={data?.datePO}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Mode of Procurement:</Form.Label>
                <FormControl
                  onChange={handleChange}
                  name='modeOfProcurement'
                  placeholder='Enter Here'
                  readOnly={canEdit === false}
                  value={data?.modeOfProcurement}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>PR No.:</Form.Label>
                <FormControl
                  disabled
                  name='pRNo'
                  value={data?.pRNo}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
          </div>

          <div className={styles?.terms}>
            <h6>Gentlemen:</h6>
            <p>
              Please furnish this office the following articles subject to the terms and conditions
              contained herein:
            </p>
          </div>

          <div className={`${styles?.formHeader} ${styles?.paddingTop}`}>
            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Place of Delivery:</Form.Label>
                <FormControl
                  name='placeOfDelivery'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  readOnly={canEdit === false}
                  value={data?.placeOfDelivery}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Date of Delivery:</Form.Label>
                <FormControl
                  type='date'
                  name='dateOfDelivery'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  readOnly={canEdit === false}
                  value={data?.dateOfDelivery}
                />
              </Form.Group>
            </div>
            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>Delivery Term:</Form.Label>
                <FormControl
                  name='deliveryTerm'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.deliveryTerm}
                  readOnly={canEdit === false}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Payment Term:</Form.Label>
                <FormControl
                  name='paymentTerm'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.paymentTerm}
                  readOnly={canEdit === false}
                />
              </Form.Group>
            </div>
          </div>

          <div className={styles?.form}>
            <Table>
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Quantity</th>
                  <th>Unit of Issue</th>
                  <th>Item Description</th>
                  <th>Estimated Unit Cost</th>
                  <th>Estimated Cost</th>
                </tr>
              </thead>
              <tbody>
                {data?.items &&
                  data?.items?.map((dd) => {
                    return (
                      <tr key={dd?.id}>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemNo'
                            value={dd?.itemNo}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='quantity'
                            value={dd?.quantity}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='unitOfIssue'
                            value={dd?.unitOfIssue}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemDescription'
                            placeholder='Enter Here'
                            value={dd?.itemDescription}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='estimatedUnitCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedUnitCost}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='estimatedCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedCost}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          <div className={styles?.totalAmount}>
            <Form.Group className={styles?.group}>
              <Form.Label>Total Amount in Cash:</Form.Label>
              <Form.Control
                readOnly
                name='totalAmount'
                onChange={handleChange}
                placeholder='Enter Here'
                value={data?.totalAmount}
              />
            </Form.Group>
          </div>

          <div className={styles?.failure}>
            <p>
              In case of failure to make the full delivery within the time specified above, a
              penalty of one-tenth (1/10) of one percent for every day of delay shall be imposed.
            </p>
          </div>

          <div className={styles?.signatures}>
            <div className={styles?.left}>
              <p>Conforme:</p>

              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <div className={styles?.input}>
                  {data?.supplyOfficerSignature && data?.supplyOfficerSignature !== null && (
                    <Image className={styles?.signatureImage} src={data?.supplyOfficerSignature} />
                  )}

                  <div className={styles?.requestedBy}>
                    <SelectUser
                      isDisabled
                      show={show}
                      stylesOptions={stylesOptions}
                      data={data?.supplyOfficerName}
                      onChange={handleUserSelectRequestedBy}
                      params={{
                        per_page: 1000,
                        ['roles[]']: 'Account Officer',
                      }}
                    />
                  </div>
                </div>

                <Form.Label>Signature over printed name</Form.Label>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Control
                  readOnly
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='supplyOfficerSignatureDate'
                  value={data?.supplyOfficerSignatureDate}
                />
                <Form.Label>Date</Form.Label>
              </Form.Group>
            </div>
            <div className={styles?.right}>
              <p>Conforme:</p>

              {/* <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <Form.Control
                  readOnly
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='authorizedOfficial'
                  value={data?.authorizedOfficial}
                />
                <Form.Label>Authorized Official</Form.Label>
              </Form.Group> */}
              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <div className={styles?.input}>
                  {data?.executiveOfficerSignature && data?.executiveOfficerSignature !== null && (
                    <Image
                      className={styles?.signatureImage}
                      src={data?.executiveOfficerSignature}
                    />
                  )}

                  <div className={styles?.requestedBy}>
                    <SelectUser
                      isDisabled
                      show={show}
                      stylesOptions={stylesOptions}
                      data={data?.executiveOfficerName}
                      onChange={handleUserSelectRequestedBy}
                      params={{
                        per_page: 1000,
                        ['roles[]']: 'Executive Officer',
                      }}
                    />
                  </div>
                </div>

                <Form.Label>Signature over printed name</Form.Label>
              </Form.Group>
            </div>
          </div>

          <div className={styles?.failure}>
            <p>
              (In case of Negotiated Purchase pursuant to Section 369 (a) of RA 7160, this portion
              must be accomplished.)
            </p>
          </div>

          <div className={styles?.resolution}>
            <Form.Group className={styles?.group}>
              <Form.Label>Approved per Sanggunian Resolution No.:</Form.Label>
              <Form.Control
                disabled
                onChange={handleChange}
                placeholder='Enter Here'
                name='approvedSanggunianResolutionNo'
                value={data?.approvedSanggunianResolutionNo}
              />
            </Form.Group>
          </div>

          <div className={styles?.negotiate}>
            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Certified Correct:</Form.Label>
                <FormControl
                  disabled
                  name='certifiedCorrect'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.certifiedCorrect}
                />
              </Form.Group>
            </div>
            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>Date:</Form.Label>
                <FormControl
                  disabled
                  name='date'
                  value={data?.date}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
          </div>

          {data?.status === 'approved' && (
            <div className={styles?.approved}>
              <p>APPROVED</p>
            </div>
          )}

          {data?.status === 'rejected' && (
            <>
              <Form.Group className={styles?.remarks}>
                <Form.Label>Reject Remarks</Form.Label>
                <Form.Control
                  rows={5}
                  as='textarea'
                  value={data?.rejectRemarks}
                  readOnly={data?.status !== 'for approval'}
                />
              </Form.Group>

              <div className={styles?.rejected}>
                <p>REJECTED</p>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles?.buttons}>
        <ConditionalRender
          condition={canApprovePO && data?.status === 'for approval'}
          renderIf={
            <>
              <Button
                onClick={handleApprove}
                className={styles?.save}
                disabled={approveLogs?.isLoading}
              >
                Approve Order
              </Button>
              <Button onClick={openRemarks} className={styles?.cancel}>
                Reject Order
              </Button>
            </>
          }
        />

        <ConditionalRender
          condition={canCreatePO && data?.status === 'for approval'}
          renderIf={
            <>
              <Button
                onClick={handleSave}
                className={styles?.save}
                disabled={updateLogs?.isLoading}
              >
                Save PO
              </Button>
              <Button onClick={handleClose} className={styles?.cancel}>
                Cancel
              </Button>
            </>
          }
        />
      </div>

      <Remarks
        data={data}
        showRemarks={showRemarks}
        handleClose={closeRemarks}
        handleChange={handleChange}
        isLoading={declinedLogs?.isLoading}
        handleSubmitRemarks={handleSubmitRemarks}
      />
    </div>
  );
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    height: '38px',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '0',
    textAlign: 'center',
    fontStyle: 'italic',
    background: 'transparent',
    borderBottom: 'solid 1px #000',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '14px',
    background: '#F3F3F3',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

export default PurchaseOrderModal;