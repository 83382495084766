/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FormControl, Table, Image } from 'react-bootstrap';

import Remarks from '../Remarks';
import SelectUser from './SelectUser';
import { checkEmpty } from 'utils/validator';
import FormNumber from 'components/GlobalUIs/FormNumber';
import ImageUpload from 'components/GlobalUIs/ImageUpload';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';
import { selectInventory, requestItem } from 'reducers/inventoryReducer';

import styles from './PurchaseOrder.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';
import { ReactComponent as PrintSVG } from 'assets/svg/prime_print.svg';
import { ReactComponent as ExportSVG } from 'assets/svg/icon-park_export.svg';

const PurchaseOrderModal = ({ show = false, tableData, setShowViewOrder }) => {
  const [showRemarks, setShowRemarks] = useState(false);
  const [data, setData] = useState({
    lgu: '',
    section: '',
    department: '',
    items: [
      {
        id: uuidv4(),
        itemNo: '',
        quantity: '',
        unitOfIssue: '',
        itemDescription: '',
        estimatedUnitCost: '',
        estimatedCost: '',
      },
    ],

    // New
    pOno: '',
    datePO: '',
    modeOfProcurement: '',
    pRNo: '',
    placeOfDelivery: '',
    dateOfDelivery: '',
    deliveryTerm: '',
    paymentTerm: '',
    totalAmount: '',
    printedName: '',
    signatureDate: '',
    authorizedOfficial: '',
    approvedSanggunianResolutionNo: '',
    certifiedCorrect: '',
    date: '',
    // For Reject Remarks
    remarks: '',
  });

  const [lguOptions, setLguOptions] = useState([]);
  const [itemOptions, setitemsOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const handleClose = () => {
    setShowViewOrder({
      data: null,
      show: false,
    });

    setData({
      lgu: '',
      prNo: '',
      saiNo: '',
      address: '',
      alobsNo: '',
      prNoDate: '',
      supplier: '',
      saiNoDate: '',
      alobsNoDate: '',
      items: [
        {
          id: uuidv4(),
          itemNo: '',
          quantity: '',
          unitOfIssue: '',
          itemDescription: '',
          estimatedUnitCost: '',
          estimatedCost: '',
        },
      ],
      purpose: '',
      requestedBy: '',
      requestedDesignation: '',
      approvedBy: '',
      approvedDesignation: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const closeRemarks = () => {
    setShowRemarks(false);
  };

  const openRemarks = () => {
    setShowRemarks(true);
  };

  const handleSubmitRemarks = () => {
    closeRemarks();
  };

  const handleUserSelectRequestedBy = (e) => {
    setData((prev) => {
      return {
        ...prev,
        requestedBy: e,
        requestedByID: e?.id,
        requestedDesignation: e?.attributes?.role,
        requestedBySignature: e?.attributes?.signature,
      };
    });
  };

  const separateDateAndTime = (dateTimeString) => {
    if (!dateTimeString) return;

    const dateObject = new Date(dateTimeString);

    const month = dateObject.toLocaleString('default', { month: 'short' });
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

    return { date: dateString, time: timeString };
  };

  useEffect(() => {
    if (show && tableData) {
      const getLGUs = async () => {
        try {
          axios.get('api/lgu').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setLguOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getDepartments = async () => {
        try {
          axios.get('api/department').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setDepartmentOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getSections = async () => {
        try {
          axios.get('api/section').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setSectionOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getItems = async () => {
        try {
          axios.get('api/inventory').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.id,
                label: dd?.id,
              };
            });

            setitemsOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      getLGUs();
      getItems();
      getSections();
      getDepartments();

      const items = tableData?.items?.map((dd) => ({
        id: dd?.id,
        quantity: dd?.attributes?.quantity,
        unitOfIssue: dd?.attributes?.unit_of_issue,
        estimatedCost: dd?.attributes?.estimated_cost,
        itemDescription: dd?.attributes?.item_description,
        estimatedUnitCost: dd?.attributes?.estimated_unit_cost,
        itemNo: dd?.attributes?.item_no,
        // itemNo: { label: dd?.attributes?.item_no, value: dd?.attributes?.item_no },
      }));

      try {
        axios
          .get('api/users', {
            params: {
              per_page: 1000,
              ['roles[0]']: 'Supply Officer',
              ['roles[1]']: 'Account Officer',
            },
          })
          .then((response) => {
            const requestByID =
              tableData?.purchaseRequestData[0]?.attributes?.requested_by_id || null;

            const filteredRequestedByData = response?.data?.data?.find(
              (dd) => +dd?.id === +requestByID,
            );

            const filteredApprovedByData = response?.data?.data?.find(
              (dd) => +dd?.id === +tableData?.approved_by_id,
            );

            console.log('tableData', tableData);
            console.log('filteredRequestedByData', filteredRequestedByData);

            const { date, time } = separateDateAndTime(tableData?.attributes?.created_at);

            setData((prev) => {
              return {
                ...prev,
                ...tableData,
                items: items,
                lgu: tableData?.lgu,
                prNo: tableData?.pr_no,
                pOno: tableData?.po_no,
                pRNo: tableData?.pr_no,
                saiNo: tableData?.sai_no,
                alobsNo: tableData?.alobs_no,
                datePO: tableData?.po_no_date,
                prNoDate: tableData?.pr_no_date,
                saiNoDate: tableData?.sai_no_date,
                paymentTerm: tableData?.payment_term,
                address: tableData?.supplier_address,
                alobsNoDate: tableData?.alobs_no_date,
                deliveryTerm: tableData?.delivery_term,
                approvedByID: tableData?.approved_by_id,
                rejectRemarks: tableData?.reject_remarks,
                requestedByID: tableData?.requested_by_id,
                dateOfDelivery: tableData?.date_of_delivery,
                supplyOfficerSignatureDate: date,
                totalAmount: tableData?.total_amount_in_cash,
                placeOfDelivery: tableData?.place_of_delivery,
                modeOfProcurement: tableData?.mode_of_procurement,
                approvedDesignation: tableData?.approved_by_designation,
                requestedDesignation: tableData?.requested_by_designation,
                section: { label: tableData?.section, value: tableData?.section },
                approvedBySignature: filteredApprovedByData?.attributes?.signature,
                supplyOfficerSignature: filteredRequestedByData?.attributes?.signature,
                executiveOfficerSignature: tableData?.attributes?.executive_conforme_by_esignature,
                requestedBy: { label: tableData?.requestBy, value: tableData?.requestBy },
                approvedBy: { label: tableData?.approvedBy, value: tableData?.approvedBy },
                department: { label: tableData?.department, value: tableData?.department },
                executiveOfficerName: tableData?.attributes?.executive_conforme_by,
                supplyOfficerName: filteredRequestedByData?.attributes?.first_name,
              };
            });
          });
      } catch (error) {
        console.log('Error', error);
      }
    }
  }, [show, tableData]);

  return (
    <div className={`${styles?.addItem} ${show ? styles?.active : ''}`}>
      <div className={styles?.padding}>
        <div className={styles?.header}>
          <p>PURCHASE ORDER</p>

          <CloseSVG className={styles?.close} onClick={handleClose} />
        </div>

        <div className={styles?.body}>
          <hr className={styles?.hr} />

          <Form.Group className={styles?.centerGroup}>
            <FormControl
              readOnly
              name='lgu'
              value={data?.lgu}
              onChange={handleChange}
              placeholder='Enter Here'
            />
            <Form.Label>LGU</Form.Label>
          </Form.Group>

          <div className={styles?.formHeader}>
            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Supplier</Form.Label>
                <FormControl
                  readOnly
                  name='supplier'
                  value={data?.supplier}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Address</Form.Label>
                <FormControl
                  readOnly
                  name='address'
                  value={data?.address}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>P.O. No.:</Form.Label>
                <FormControl
                  readOnly
                  name='pOno'
                  value={data?.pOno}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Date:</Form.Label>
                <FormControl
                  readOnly
                  name='datePO'
                  value={data?.datePO}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Mode of Procurement:</Form.Label>
                <FormControl
                  readOnly
                  onChange={handleChange}
                  name='modeOfProcurement'
                  placeholder='Enter Here'
                  value={data?.modeOfProcurement}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>PR No./s:</Form.Label>
                <FormControl
                  readOnly
                  name='pRNo'
                  value={data?.pRNo}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
          </div>

          <div className={styles?.terms}>
            <h6>Gentlemen:</h6>
            <p>
              Please furnish this office the following articles subject to the terms and conditions
              contained herein:
            </p>
          </div>

          <div className={`${styles?.formHeader} ${styles?.paddingTop}`}>
            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Place of Delivery:</Form.Label>
                <FormControl
                  readOnly
                  name='placeOfDelivery'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.placeOfDelivery}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Date of Delivery:</Form.Label>
                <FormControl
                  readOnly
                  type='date'
                  name='dateOfDelivery'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.dateOfDelivery}
                />
              </Form.Group>
            </div>
            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>Delivery Term:</Form.Label>
                <FormControl
                  readOnly
                  name='deliveryTerm'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.deliveryTerm}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Payment Term:</Form.Label>
                <FormControl
                  readOnly
                  name='paymentTerm'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={data?.paymentTerm}
                />
              </Form.Group>
            </div>
          </div>

          <div className={styles?.form}>
            <Table>
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Quantity</th>
                  <th>Unit of Issue</th>
                  <th>Item Description</th>
                  <th>Estimated Unit Cost</th>
                  <th>Estimated Cost</th>
                </tr>
              </thead>
              <tbody>
                {data?.items &&
                  data?.items?.map((dd) => {
                    return (
                      <tr key={dd?.id}>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemNo'
                            value={dd?.itemNo}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='quantity'
                            value={dd?.quantity}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='unitOfIssue'
                            value={dd?.unitOfIssue}
                            placeholder='Enter Here'
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemDescription'
                            placeholder='Enter Here'
                            value={dd?.itemDescription}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='estimatedUnitCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedUnitCost}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            type='number'
                            name='estimatedCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedCost}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          <div className={styles?.totalAmount}>
            <Form.Group className={styles?.group}>
              <Form.Label>Total Amount in Cash:</Form.Label>
              <Form.Control
                readOnly
                name='totalAmount'
                onChange={handleChange}
                placeholder='Enter Here'
                value={data?.totalAmount}
              />
            </Form.Group>
          </div>

          <div className={styles?.failure}>
            <p>
              In case of failure to make the full delivery within the time specified above, a
              penalty of one-tenth (1/10) of one percent for every day of delay shall be imposed.
            </p>
          </div>

          <div className={styles?.signatures}>
            <div className={styles?.left}>
              <p>Conforme:</p>

              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <div className={styles?.input}>
                  {data?.supplyOfficerSignature && data?.supplyOfficerSignature !== null && (
                    <Image className={styles?.signatureImage} src={data?.supplyOfficerSignature} />
                  )}

                  <div className={styles?.requestedBy}>
                    <Form.Control
                      readOnly
                      onChange={handleChange}
                      placeholder='Enter Here'
                      name='supplyOfficerName'
                      value={data?.supplyOfficerName}
                    />
                  </div>
                </div>

                <Form.Label>Signature over printed name</Form.Label>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Control
                  readOnly
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='supplyOfficerSignatureDate'
                  value={data?.supplyOfficerSignatureDate}
                />
                <Form.Label>Date</Form.Label>
              </Form.Group>
            </div>
            <div className={styles?.right}>
              <p>Conforme:</p>

              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <div className={styles?.input}>
                  {data?.executiveOfficerSignature && data?.executiveOfficerSignature !== null && (
                    <Image
                      className={styles?.signatureImage}
                      src={data?.executiveOfficerSignature}
                    />
                  )}

                  <div className={styles?.requestedBy}>
                    <Form.Control
                      readOnly
                      onChange={handleChange}
                      placeholder='Enter Here'
                      name='executiveOfficerName'
                      value={data?.executiveOfficerName}
                    />
                  </div>
                </div>

                <Form.Label>Signature over printed name</Form.Label>
              </Form.Group>
            </div>
          </div>

          <div className={styles?.failure}>
            <p>
              (In case of Negotiated Purchase pursuant to Section 369 (a) of RA 7160, this portion
              must be accomplished.)
            </p>
          </div>

          <div className={styles?.resolution}>
            <Form.Group className={styles?.group}>
              <Form.Label>Approved per Sanggunian Resolution No.:</Form.Label>
              <Form.Control
                onChange={handleChange}
                placeholder='Enter Here'
                name='approvedSanggunianResolutionNo'
                value={data?.approvedSanggunianResolutionNo}
              />
            </Form.Group>
          </div>

          <div className={styles?.negotiate}>
            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Certified Correct:</Form.Label>
                <FormControl
                  name='certifiedCorrect'
                  value={data?.certifiedCorrect}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
            <div className={styles?.col2}>
              <Form.Group className={styles?.group}>
                <Form.Label>Date:</Form.Label>
                <FormControl
                  name='date'
                  value={data?.date}
                  onChange={handleChange}
                  placeholder='Enter Here'
                />
              </Form.Group>
            </div>
          </div>

          <div className={`${styles?.transactionStatus} ${styles[data?.status]}`}>
            <p>{data?.status}</p>
          </div>

          <div className={styles?.actions}>
            <Button className={styles?.export}>
              <ExportSVG />
              Export
            </Button>
            <Button className={styles?.print}>
              <PrintSVG />
              Print
            </Button>
          </div>
        </div>
      </div>

      {/* <div className={styles?.buttons}>
        <Button className={styles?.save}>Save PO</Button>
        <Button onClick={handleClose} className={styles?.cancel}>
          Cancel
        </Button>
      </div> */}

      <Remarks
        data={data}
        showRemarks={showRemarks}
        handleClose={closeRemarks}
        handleChange={handleChange}
        handleSubmitRemarks={handleSubmitRemarks}
      />
    </div>
  );
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    height: '38px',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '0',
    textAlign: 'center',
    fontStyle: 'italic',
    background: 'transparent',
    borderBottom: 'solid 1px #000',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '14px',
    background: '#F3F3F3',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

export default PurchaseOrderModal;
