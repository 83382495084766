/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Image, Container, Row, Col, FormControl } from 'react-bootstrap';

// Components
import { checkEmpty } from 'utils/validator';
import SignaturePad from 'components/SignaturePad';
import { updateUserinfo } from 'reducers/authReducer';
import FormNumber from 'components/GlobalUIs/FormNumber';
import ImageUpload from 'components/GlobalUIs/ImageUpload';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import { getProfile, updateProfile, getUsersState } from 'reducers/usersReducer';

// Assets
import styles from './index.module.scss';
import { ReactComponent as ArrowSVG } from 'assets/svg/arrow left.svg';

// Main component
const UserAccount = () => {
  const dispatch = useDispatch();

  const userState = useSelector(getUsersState);
  const { updateLogs } = userState;

  const [readOnly, setReadOnly] = useState(true);
  const [defaultData, setDefaultData] = useState({});
  const [editButton, setEditButton] = useState('Edit');

  const [showSignature, setShowSignature] = useState({
    name: '',
    show: false,
  });

  const [data, setData] = useState({
    role: '',
    email: '',
    status: '',
    gender: '',
    birthday: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    profile_pic: [],
    mobile_number: '',
    signature: '',
  });

  // eslint-disable-next-line newline-per-chained-call
  const today = new Date().toISOString().split('T')[0];

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const isBase64 = (data) => {
    const base64PNGRegex =
      /^data:image\/png;base64,([A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    return base64PNGRegex.test(data);
  };

  const handleEdit = async () => {
    if (editButton === 'Edit') {
      setReadOnly(false);
      setEditButton('Save');
    } else {
      const newData = {
        gender: data?.gender,
        birthday: data?.birthday,
        last_name: data?.last_name,
        middle_name: data?.middle_name,
        first_name: data?.first_name,
        profile_pic: data?.profile_pic,
        mobile_number: data?.mobile_number,
        signature: data?.signature,
      };

      const result = checkEmpty(newData);

      if (result) {
        toast.error('Please fill in the blank to proceed');
        return;
      }

      const formData = new FormData();

      formData.append('gender', data?.gender);
      formData.append('birthday', data?.birthday);
      formData.append('last_name', data?.last_name);
      formData.append('first_name', data?.first_name);
      formData.append('middle_name', data?.middle_name);
      formData.append('mobile_number', data?.mobile_number);

      const res = isBase64(data?.signature);
      console.log('res', res);

      if (res) {
        formData.append('signature', data?.signature);
      }

      if (typeof data?.profile_pic !== 'string') {
        formData.append('profile_pic', data?.profile_pic[0]);
      }

      try {
        const obj = {
          id: data?.id,
          params: formData,
        };

        const response = await dispatch(updateProfile(obj));

        if (response?.meta?.requestStatus === 'rejected') {
          toast.error(response?.payload?.errors[0]?.detail);
          return;
        }

        if (response) {
          getProfileData(true);
          setReadOnly(true);
          setEditButton('Edit');
          toast.success('Successfully updated profile data');
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleCancel = () => {
    setReadOnly(true);
    setData(defaultData);
    setEditButton('Edit');
  };

  const getProfileData = async (toUpdate = false) => {
    try {
      const response = await dispatch(getProfile({}));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        if (toUpdate) {
          dispatch(updateUserinfo(response?.payload?.data));
        }

        setData({ ...response?.payload?.data?.attributes, id: response?.payload?.data?.id });
        setDefaultData({ ...response?.payload?.data?.attributes, id: response?.payload?.data?.id });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  console.log('STATE DATA', data);

  return (
    <PrivateLayout pageTitle='Profile'>
      <div className={styles?.profile}>
        <Container>
          <Row>
            <Col>
              <Link className={styles?.back} to={'/'}>
                <ArrowSVG />
                Back to Dashboard
              </Link>

              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <h5>Profile</h5>
                </div>

                <div className={styles?.body}>
                  <div className={styles?.image}>
                    <div className={styles?.imageFile}>
                      {editButton === 'Save' ? (
                        <ImageUpload
                          name='profile_pic'
                          setState={setData}
                          stateValue={data?.profile_pic}
                        />
                      ) : (
                        <Image src={data?.profile_pic} />
                      )}
                    </div>

                    <div className={styles?.buttons}>
                      <Button
                        onClick={handleEdit}
                        className={styles?.edit}
                        disabled={updateLogs?.isLoading}
                      >
                        {editButton}
                      </Button>

                      {readOnly === false && (
                        <Button onClick={handleCancel} className={styles?.remove}>
                          Cancel
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className={styles?.form}>
                    <Form.Group className={styles?.group}>
                      <Form.Label>Role:</Form.Label>
                      <FormControl
                        readOnly
                        name='role'
                        value={data?.role}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Email:</Form.Label>
                      <FormControl
                        readOnly
                        name='email'
                        value={data?.email}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Date Joined:</Form.Label>
                      <FormControl
                        readOnly
                        name='created_at'
                        onChange={handleChange}
                        value={data?.created_at}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Status:</Form.Label>
                      <FormControl
                        readOnly
                        name='status'
                        value={data?.status}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Firstname:</Form.Label>
                      <FormControl
                        name='first_name'
                        readOnly={readOnly}
                        onChange={handleChange}
                        value={data?.first_name}
                        className={readOnly === false ? styles?.editing : ''}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Middlename:</Form.Label>
                      <FormControl
                        name='middle_name'
                        readOnly={readOnly}
                        onChange={handleChange}
                        value={data?.middle_name}
                        className={readOnly === false ? styles?.editing : ''}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Lastname:</Form.Label>
                      <FormControl
                        name='last_name'
                        readOnly={readOnly}
                        onChange={handleChange}
                        value={data?.last_name}
                        className={readOnly === false ? styles?.editing : ''}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Mobile Number:</Form.Label>
                      <FormNumber
                        name='mobile_number'
                        readOnly={readOnly}
                        onChange={handleChange}
                        value={data?.mobile_number}
                        className={readOnly === false ? styles?.editing : ''}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Birthday:</Form.Label>
                      <FormControl
                        type='date'
                        max={today}
                        name='birthday'
                        readOnly={readOnly}
                        value={data?.birthday}
                        onChange={handleChange}
                        className={readOnly === false ? styles?.editing : ''}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Gender:</Form.Label>
                      <Form.Select
                        name='gender'
                        disabled={readOnly}
                        value={data?.gender}
                        onChange={handleChange}
                        className={readOnly === false ? styles?.editing : ''}
                      >
                        <option value='' hidden>
                          Select Gender
                        </option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className={styles?.signatureGroup}>
                      <Form.Label>Signature:</Form.Label>
                      <div
                        className={styles?.signatureContainer}
                        onClick={() => {
                          if (readOnly) return;

                          setShowSignature({
                            show: true,
                            name: 'signature',
                          });
                        }}
                      >
                        <Image name='signature' src={data?.signature} className={styles?.signature} />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <SignaturePad
          setData={setData}
          show={showSignature?.show}
          name={showSignature?.name}
          handleClose={() => setShowSignature(false)}
        />
      </div>
    </PrivateLayout>
  );
};

export default UserAccount;
