/* eslint-disable multiline-comment-style */
/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FormControl, Table, Image } from 'react-bootstrap';

import Remarks from '../Remarks';
import { checkEmpty } from 'utils/validator';
import SelectUser from 'components/Layouts/SelectUser';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';
import {
  denyRequestForm,
  updateRequestForm,
  approveRequestForm,
  selectPurchaseRequest,
} from 'reducers/purchaseRequestReducer';

import styles from './ViewRequest.module.scss';
import { ReactComponent as CirclePlus } from 'assets/svg/add.svg';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

const ViewRequest = ({ show = false, tableData, setShowViewRequest, handlePurchaseRequest }) => {
  const dispatch = useDispatch();

  const purchaseRequestState = useSelector(selectPurchaseRequest);
  const { updateRequestFormLogs, approveLogs, declinedLogs } = purchaseRequestState;

  const [showRemarks, setShowRemarks] = useState(false);
  const [data, setData] = useState({
    id: '',
    lgu: '',
    prNo: '',
    saiNo: '',
    section: '',
    alobsNo: '',
    prNoDate: '',
    saiNoDate: '',
    department: '',
    alobsNoDate: '',
    items: [
      {
        id: uuidv4(),
        itemNo: '',
        quantity: '',
        unitOfIssue: '',
        itemDescription: '',
        estimatedUnitCost: '',
        estimatedCost: '',
      },
    ],
    purpose: '',
    requestedBy: '',
    requestedByID: '',
    requestedBySignature: null,
    requestedDesignation: '',
    approvedBy: '',
    approvedByID: '',
    approvedBySignature: null,
    approvedDesignation: '',
    remarks: '',
  });

  const [lguOptions, setLguOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [itemOptions, setitemsOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const handleClose = () => {
    setShowViewRequest(false);

    setData({
      lgu: '',
      prNo: '',
      saiNo: '',
      section: '',
      alobsNo: '',
      prNoDate: '',
      saiNoDate: '',
      department: '',
      alobsNoDate: '',
      items: [
        {
          id: uuidv4(),
          itemNo: '',
          quantity: '',
          unitOfIssue: '',
          itemDescription: '',
          estimatedUnitCost: '',
          estimatedCost: '',
        },
      ],
      purpose: '',
      requestedBy: '',
      requestedDesignation: '',
      approvedBy: '',
      approvedDesignation: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTableChange = (e) => (tableData) => {
    const { name, value } = e.target;

    const newItemData = data?.items?.map((dd) => {
      if (dd?.id === tableData?.id) {
        console.log('found');
        return {
          ...dd,
          [name]: value,
        };
      }

      return dd;
    });

    console.log('newItemData', newItemData);

    setData((prev) => ({ ...prev, items: newItemData }));
  };

  const handleTotalAmount = (e) => (tableData) => {
    const { name, value } = e.target;

    const total = +value * +tableData?.quantity;

    const newItemData = data?.items?.map((dd) => {
      if (dd?.id === tableData?.id) {
        return {
          ...dd,
          [name]: value,
          estimatedCost: total,
        };
      }

      return dd;
    });

    setData((prev) => ({ ...prev, items: newItemData }));
  };

  const addRow = () => {
    const newRow = {
      id: uuidv4(),
      itemNo: '',
      quantity: '',
      unitOfIssue: '',
      itemDescription: '',
      estimatedUnitCost: '',
      estimatedCost: '',
    };

    const _items = data?.items;
    const _newItems = _items.concat([newRow]);

    setData((prev) => ({ ...prev, items: _newItems }));
  };

  const closeRemarks = () => {
    setShowRemarks(false);
  };

  const openRemarks = () => {
    setShowRemarks(true);
  };

  const handleSubmitRemarks = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('reject_remarks', data?.remarks);

      const response = await dispatch(denyRequestForm({ params: formData, id: data?.id }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        closeRemarks();
        handlePurchaseRequest();
        toast.info('Successfully Declined Purchase Request');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSelectedItem = (e) => (name) => {
    setData((prev) => {
      return {
        ...prev,
        [name]: e,
      };
    });
  };

  const handleTableSelectedItem = (e, dataName, tableData) => {
    const newItemData = data?.items?.map((dd) => {
      if (dd?.id === tableData?.id) {
        return {
          ...dd,
          [dataName]: e,
          itemDescription: e?.attributes?.item_name,
        };
      }

      return dd;
    });

    setData((prev) => ({ ...prev, items: newItemData }));
  };

  const handleUserSelectRequestedBy = (e) => {
    setData((prev) => {
      return {
        ...prev,
        requestedBy: e,
        requestedByID: e?.id,
        requestedDesignation: e?.attributes?.role,
        requestedBySignature: e?.attributes?.signature,
      };
    });
  };

  const handleUserSelectApprovedBy = (e) => {
    setData((prev) => {
      return {
        ...prev,
        approvedBy: e,
        approvedByID: e?.id,
        approvedDesignation: e?.attributes?.role,
        approvedBySignature: e?.attributes?.signature,
      };
    });
  };

  const handleUpdate = async () => {
    const saveData = {
      pr_no: data?.prNo,
      sai_no: data?.saiNo,
      purpose: data?.purpose,
      alobs_no: data?.alobsNo,
      lgu_name: data?.lgu?.label,
      pr_no_date: data?.prNoDate,
      sai_no_date: data?.saiNoDate,
      alobs_no_date: data?.alobsNoDate,
      section_name: data?.section?.label,
      requested_by_id: data?.requestedByID,
      department_name: data?.department?.label,
      items: data?.items?.map((dd) => {
        return {
          // id: dd?.id, No need to pass ID
          quantity: dd?.quantity,
          item_no: dd?.itemNo?.value,
          unit_of_issue: dd?.unitOfIssue,
          estimated_cost: dd?.estimatedCost,
          item_description: dd?.itemDescription,
          estimated_unit_cost: dd?.estimatedUnitCost,
        };
      }),
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData, ['items']);

    let arrCheck = false;
    saveData?.items?.forEach((dd) => {
      const result = checkEmpty(dd);

      if (result) {
        arrCheck = result;
      }
    });

    if (emptyCheck || arrCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    const formData = new URLSearchParams();
    // formData.append('pr_no', saveData?.pr_no);
    formData.append('sai_no', saveData?.sai_no);
    formData.append('purpose', saveData?.purpose);
    formData.append('alobs_no', saveData?.alobs_no);
    formData.append('lgu_name', saveData?.lgu_name);
    // formData.append('pr_no_date', saveData?.pr_no_date);
    formData.append('sai_no_date', saveData?.sai_no_date);
    formData.append('alobs_no_date', saveData?.alobs_no_date);
    formData.append('section_name', saveData?.section_name);
    formData.append('requested_by_id', saveData?.requested_by_id);
    formData.append('department_name', saveData?.department_name);

    saveData?.items?.forEach((element, idx) => {
      formData.append(`items[${idx}]`, JSON.stringify(element));
    });

    try {
      const response = await dispatch(updateRequestForm({ params: formData, id: data?.id }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseRequest();
        toast.info('Successfully Updated Purchase Request');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleApprove = async () => {
    // const checkData = {
    //   approvedByID: data?.approvedByID,
    // };

    // let emptyCheck = false;
    // emptyCheck = checkEmpty(checkData, ['items']);

    // if (emptyCheck) {
    //   toast.error('Please fill in the empty fields');
    //   return;
    // }

    try {
      const response = await dispatch(approveRequestForm(data?.id));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handlePurchaseRequest();
        toast.info('Successfully Approved Purchase Request');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (show && tableData) {
      const getLGUs = async () => {
        try {
          axios.get('api/lgu').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setLguOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getDepartments = async () => {
        try {
          axios.get('api/department').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setDepartmentOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getSections = async () => {
        try {
          axios.get('api/section').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.attributes?.name,
                label: dd?.attributes?.name,
              };
            });

            setSectionOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      const getItems = async () => {
        try {
          axios.get('api/inventory').then((response) => {
            const newData = response?.data?.data.map((dd) => {
              return {
                ...dd,
                value: dd?.id,
                label: dd?.id,
              };
            });

            setitemsOptions(newData);
          });
        } catch (error) {
          console.log('Error', error);
        }
      };

      getLGUs();
      getItems();
      getSections();
      getDepartments();

      const items = tableData?.items?.map((dd) => ({
        id: dd?.id,
        quantity: dd?.attributes?.quantity,
        unitOfIssue: dd?.attributes?.unit_of_issue,
        estimatedCost: dd?.attributes?.estimated_cost,
        itemDescription: dd?.attributes?.item_description,
        estimatedUnitCost: dd?.attributes?.estimated_unit_cost,
        itemNo: { label: dd?.attributes?.item_no, value: dd?.attributes?.item_no },
      }));

      try {
        axios
          .get('api/users', {
            params: {
              per_page: 1000,
              ['roles[0]']: 'Supply Officer',
              ['roles[1]']: 'Account Officer',
            },
          })
          .then((response) => {
            const filteredRequestedByData = response?.data?.data?.find(
              (dd) => +dd?.id === +tableData?.requested_by_id,
            );

            const filteredApprovedByData = response?.data?.data?.find(
              (dd) => +dd?.id === +tableData?.approved_by_id,
            );

            console.log('filteredApprovedByData', filteredApprovedByData);

            setData((prev) => {
              return {
                ...prev,
                ...tableData,
                items: items,
                prNo: tableData?.pr_no,
                saiNo: tableData?.sai_no,
                alobsNo: tableData?.alobs_no,
                prNoDate: tableData?.pr_no_date,
                saiNoDate: tableData?.sai_no_date,
                alobsNoDate: tableData?.alobs_no_date,
                approvedByID: tableData?.approved_by_id,
                rejectRemarks: tableData?.reject_remarks,
                requestedByID: tableData?.requested_by_id,
                lgu: { label: tableData?.lgu, value: tableData?.lgu },
                approvedDesignation: tableData?.approved_by_designation,
                requestedDesignation: tableData?.requested_by_designation,
                section: { label: tableData?.section, value: tableData?.section },
                approvedBySignature: filteredApprovedByData?.attributes?.signature,
                requestedBySignature: filteredRequestedByData?.attributes?.signature,
                requestedBy: { label: tableData?.requestBy, value: tableData?.requestBy },
                approvedBy: { label: tableData?.approvedBy, value: tableData?.approvedBy },
                department: { label: tableData?.department, value: tableData?.department },
              };
            });
          });
      } catch (error) {
        console.log('Error', error);
      }
    }
  }, [show, tableData]);

  const canApproveRequest = checkStringPermission('can approve purchase request');

  return (
    <div className={`${styles?.addItem} ${show ? styles?.active : ''}`}>
      <div className={styles?.padding}>
        <div className={styles?.header}>
          <p>PURCHASE REQUEST FORM</p>

          <CloseSVG className={styles?.close} onClick={handleClose} />
        </div>

        <div className={styles?.body}>
          <hr className={styles?.hr} />

          <Form.Group className={styles?.centerGroup}>
            <Select
              value={data?.lgu}
              options={lguOptions}
              styles={stylesOptions}
              placeholder='Enter Here'
              onChange={(e) => handleSelectedItem(e)('lgu')}
              isDisabled={data?.status !== 'for approval' || canApproveRequest === true}
            />
            <Form.Label>LGU</Form.Label>
          </Form.Group>

          <div className={styles?.formHeader}>
            <div className={styles?.col}>
              <Form.Group className={styles?.group}>
                <Form.Label>Department</Form.Label>
                <div className={styles?.selectContainer}>
                  <Select
                    styles={stylesOptions}
                    placeholder='Enter Here'
                    value={data?.department}
                    options={departmentOptions}
                    onChange={(e) => handleSelectedItem(e)('department')}
                    isDisabled={data?.status !== 'for approval' || canApproveRequest === true}
                  />
                </div>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>Section</Form.Label>
                <div className={styles?.selectContainer}>
                  <Select
                    value={data?.section}
                    styles={stylesOptions}
                    placeholder='Enter Here'
                    options={sectionOptions}
                    onChange={(e) => handleSelectedItem(e)('section')}
                    isDisabled={data?.status !== 'for approval' || canApproveRequest === true}
                  />
                </div>
              </Form.Group>
            </div>
            <div className={styles?.line}></div>
            <div className={styles?.col}>
              <div className={styles?.dates}>
                <div className={styles?.split}>
                  <Form.Group className={styles?.group}>
                    <Form.Label>PR No.:</Form.Label>
                    <FormControl
                      disabled
                      name='prNo'
                      value={data?.prNo}
                      onChange={handleChange}
                      placeholder='Enter Here'
                    />
                  </Form.Group>

                  <Form.Group className={styles?.group}>
                    <Form.Label>SAI No.:</Form.Label>
                    <FormControl
                      name='saiNo'
                      value={data?.saiNo}
                      onChange={handleChange}
                      placeholder='Enter Here'
                      readOnly={data?.status !== 'for approval' || canApproveRequest === true}
                    />
                  </Form.Group>

                  <Form.Group className={styles?.group}>
                    <Form.Label>ALOBS No:.</Form.Label>
                    <FormControl
                      name='alobsNo'
                      value={data?.alobsNo}
                      onChange={handleChange}
                      placeholder='Enter Here'
                      readOnly={data?.status !== 'for approval' || canApproveRequest === true}
                    />
                  </Form.Group>
                </div>

                <div className={styles?.split}>
                  <Form.Group className={styles?.group}>
                    <Form.Label>Date</Form.Label>
                    <FormControl
                      disabled
                      type='date'
                      name='prNoDate'
                      value={data?.prNoDate}
                      onChange={handleChange}
                      placeholder='Enter Here'
                    />
                  </Form.Group>

                  <Form.Group className={styles?.group}>
                    <Form.Label>Date</Form.Label>
                    <FormControl
                      type='date'
                      name='saiNoDate'
                      value={data?.saiNoDate}
                      onChange={handleChange}
                      placeholder='Enter Here'
                      readOnly={data?.status !== 'for approval' || canApproveRequest === true}
                    />
                  </Form.Group>

                  <Form.Group className={styles?.group}>
                    <Form.Label>Date</Form.Label>
                    <FormControl
                      type='date'
                      name='alobsNoDate'
                      onChange={handleChange}
                      placeholder='Enter Here'
                      value={data?.alobsNoDate}
                      readOnly={data?.status !== 'for approval' || canApproveRequest === true}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>

          <div className={styles?.form}>
            <Table>
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Quantity</th>
                  <th>Unit of Issue</th>
                  <th>Item Description</th>
                  <th>Estimated Unit Cost</th>
                  <th>Estimated Cost</th>
                </tr>
              </thead>
              <tbody>
                {data?.items &&
                  data?.items?.map((dd) => {
                    return (
                      <tr key={dd?.id}>
                        <td>
                          <Select
                            value={dd?.itemNo}
                            options={itemOptions}
                            styles={stylesOptions}
                            placeholder='Enter Here'
                            className={styles?.itemSelect}
                            onChange={(e) => handleTableSelectedItem(e, 'itemNo', dd)}
                            isDisabled={
                              data?.status !== 'for approval' || canApproveRequest === true
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            name='quantity'
                            type='number'
                            value={dd?.quantity}
                            placeholder='Enter Here'
                            onChange={(e) => handleTableChange(e)(dd)}
                            readOnly={data?.status !== 'for approval' || canApproveRequest === true}
                          />
                        </td>
                        <td>
                          <Form.Control
                            name='unitOfIssue'
                            value={dd?.unitOfIssue}
                            placeholder='Enter Here'
                            onChange={(e) => handleTableChange(e)(dd)}
                            readOnly={data?.status !== 'for approval' || canApproveRequest === true}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            name='itemDescription'
                            placeholder='Enter Here'
                            value={dd?.itemDescription}
                            onChange={(e) => handleTableChange(e)(dd)}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type='number'
                            name='estimatedUnitCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedUnitCost}
                            onChange={(e) => handleTotalAmount(e)(dd)}
                            readOnly={data?.status !== 'for approval' || canApproveRequest === true}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type='number'
                            name='estimatedCost'
                            placeholder='Enter Here'
                            value={dd?.estimatedCost}
                            onChange={(e) => handleTableChange(e)(dd)}
                            readOnly={data?.status !== 'for approval' || canApproveRequest === true}
                          />
                        </td>
                      </tr>
                    );
                  })}

                {data?.status === 'for approval' && canApproveRequest === false && (
                  <tr>
                    <td colSpan={7}>
                      <Button onClick={addRow} className={styles?.addRow}>
                        <CirclePlus />
                        Add Row
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className={styles?.purpose}>
            <Form.Group className={styles?.group}>
              <Form.Label>Purpose:</Form.Label>
              <Form.Control
                name='purpose'
                value={data?.purpose}
                onChange={handleChange}
                placeholder='Enter Here'
                readOnly={data?.status !== 'for approval'}
              />
            </Form.Group>
          </div>

          <div className={styles?.signatures}>
            <div className={styles?.left}>
              <p>REQUESTED BY:</p>

              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <div className={styles?.input}>
                  {data?.requestedBySignature && data?.requestedBySignature !== null && (
                    <Image className={styles?.signatureImage} src={data?.requestedBySignature} />
                  )}

                  <div className={styles?.requestedBy}>
                    <SelectUser
                      show={show}
                      data={data?.requestedBy}
                      stylesOptions={stylesOptions}
                      onChange={handleUserSelectRequestedBy}
                      params={{
                        per_page: 1000,
                        ['roles[]']: 'Supply Officer',
                      }}
                    />
                  </div>
                </div>

                <Form.Label>Printed Name & Signature</Form.Label>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Control
                  readOnly
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='requestedDesignation'
                  value={data?.requestedDesignation}
                />
                <Form.Label>Designation</Form.Label>
              </Form.Group>
            </div>
            <div className={styles?.right}>
              <p>APPROVED BY:</p>

              <Form.Group className={`${styles?.group} ${styles?.marginB}`}>
                <div className={styles?.input}>
                  {data?.approvedBySignature && data?.approvedBySignature !== null && (
                    <Image className={styles?.signatureImage} src={data?.approvedBySignature} />
                  )}

                  <div className={styles?.approvedBy}>
                    <SelectUser
                      isDisabled
                      show={show}
                      data={data?.approvedBy}
                      stylesOptions={stylesOptions}
                      onChange={handleUserSelectApprovedBy}
                      // isDisabled={canApproveRequest === false}
                      params={{
                        per_page: 1000,
                        ['roles[]']: 'Account Officer',
                      }}
                    />
                  </div>
                </div>

                <Form.Label>Printed Name & Signature</Form.Label>
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Control
                  readOnly
                  onChange={handleChange}
                  placeholder='Enter Here'
                  name='approvedDesignation'
                  value={data?.approvedDesignation}
                />
                <Form.Label>Designation</Form.Label>
              </Form.Group>
            </div>
          </div>

          {data?.status === 'approved' && (
            <div className={styles?.approved}>
              <p>APPROVED</p>
            </div>
          )}

          {data?.status === 'rejected' && (
            <>
              <Form.Group className={styles?.remarks}>
                <Form.Label>Reject Remarks</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={5}
                  value={data?.rejectRemarks}
                  readOnly={data?.status !== 'for approval'}
                />
              </Form.Group>

              <div className={styles?.rejected}>
                <p>REJECTED</p>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles?.buttons}>
        <ConditionalRender
          condition={canApproveRequest && data?.status === 'for approval'}
          renderIf={
            <>
              <Button
                onClick={handleApprove}
                className={styles?.save}
                disabled={approveLogs?.isLoading}
              >
                Approve Request
              </Button>
              <Button onClick={openRemarks} className={styles?.cancel}>
                Reject Request
              </Button>
            </>
          }
        />

        <ConditionalRender
          condition={!canApproveRequest}
          renderIf={
            <>
              {data?.status === 'for approval' && (
                <>
                  <Button
                    onClick={handleUpdate}
                    className={styles?.save}
                    disabled={updateRequestFormLogs?.isLoading}
                  >
                    Save PR
                  </Button>
                  <Button onClick={handleClose} className={styles?.cancel}>
                    Cancel
                  </Button>
                </>
              )}
            </>
          }
        />
      </div>

      <Remarks
        data={data}
        showRemarks={showRemarks}
        handleClose={closeRemarks}
        handleChange={handleChange}
        isLoading={declinedLogs?.isLoading}
        handleSubmitRemarks={handleSubmitRemarks}
      />
    </div>
  );
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    height: '38px',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '0',
    background: 'transparent',
    borderBottom: 'solid 1px #000',
    textAlign: 'center',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '14px',
    background: '#F3F3F3',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

export default ViewRequest;
